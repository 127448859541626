<template>
  <div class="tag-wrapper">
    <el-tag
      :class="size"
      effect="light"
      :color="color"
      :type="computedType"
      :style="style"
      :key="computedType"
    >
      {{ label }}
    </el-tag>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";
import {
  MEASURE_STANDARD,
  MEASURE_STANDARD_MAP,
} from "@/constant/variable_standard.js";
const props = defineProps({
  type: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "default",
  },
});
const computedType = computed(() => {
  const standard = MEASURE_STANDARD_MAP[props.type];
  if (standard) {
    return standard.type;
  } else {
    return "";
  }
});

const label = computed(() => {
  const standard = MEASURE_STANDARD_MAP[props.type];
  if (standard) {
    return standard.label;
  } else {
    return "";
  }
});

const color = computed(() => {
  const standard = MEASURE_STANDARD_MAP[props.type];
  if (standard) {
    return standard.color;
  } else {
    return null;
  }
});

const style = computed(() => {
  if (props.type == MEASURE_STANDARD.OTHER) {
    return `color: #9480e4`;
  } else {
    const standard = MEASURE_STANDARD_MAP[props.type];
    return `color: ${standard?.fontColor || ""}`;
  }
});

const size = computed(() => {
  if (props.size == "default") {
    return "el-tag-default";
  } else if (props.size == "small") {
    return "el-tag-small";
  } else if (props.size == "large") {
    return "el-tag-large";
  } else {
    return "el-tag-default";
  }
});
</script>

<style lang="scss" scoped>
// .el-tag {
//   font-size: 14px;
//   font-weight: 500;
//   height: 28px;
//   width: 48px;
//   border: 0px;
// }
.el-tag-default {
  font-size: 14px;
  font-weight: 500;
  height: 28px;
  width: 48px;
  border: 0px;
}
.el-tag-small {
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  width: 40px;
  border: 0px;
}
.el-tag-large {
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  width: 55px;
  border: 0px;
}
</style>
