<!-- 泊松回归分析 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
      :transfer-options="transferOptions"
    >
      <AnalysisConditionFormItem prop="variable_y">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variable_y"
            row="1"
            @onEnd="onEnd"
            title="因变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加因变量Y',
                MEASURE_STANDARD.RATION,
                '变量（变量数=1，变量取值为非负整数)'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <AnalysisConditionFormItem prop="variable_x_list">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variable_x_list"
            row="auto"
            @onEnd="onEnd"
            title="自变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加自变量X',
                MEASURE_STANDARD.RATION,
                '变量（变量数≧1)'
              )
            "
            tipContent=""
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <AnalysisConditionFormItem prop="variable_baseline">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variable_baseline"
            row="1"
            @onEnd="onEnd"
            title="基数变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加基数N',
                MEASURE_STANDARD.RATION,
                '变量（变量数≤1)'
              )
            "
            tipContent="基数是基于某个数字的意思，比如有20个省每年癌症患者人数，但是癌症患者人数是基于某省所有人数时才有对比意义。"
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          <el-switch v-model="ifNormality" />
          保存残差和预测值
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();

const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const ifNormality = ref(true);
const emits = defineEmits(["showLoading"]);
const libraryId = inject("libraryId");
const targetProps = ref(["variable_y", "variable_x_list", "variable_baseline"]);
const transferOptions = ref([
  { prop: "variable_y", label: "因变量" },
  { prop: "variable_x_list", label: "自变量" },
  { prop: "variable_baseline", label: "基数变量" },
]);
// TODO:校验规则
const rules = ref({
  variable_y: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【因变量】变量个数不能少于1");
      },
    },
    {
      max: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【因变量】变量个数不能大于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
  variable_x_list: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【自变量】变量个数不能少于1");
      },
    },
    {
      variableStandard: [
        // MEASURE_STANDARD.CATEGORIZATION,
        MEASURE_STANDARD.RATION,
      ],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
  variable_baseline: [
    {
      max: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【基数变量】变量个数不能大于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning("请添加【定类】变量");
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    variable_y: [],
    variable_x_list: [],
    variable_baseline: [],
  },
});

const analysisConditionFormRef = ref(null);
const showLoading = () => {
  emits("showLoading");
};
const onClickStart = async () => {
  // TODO:showloading
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let params = {
        libId: libraryId,
        analysisId: props.tabInfo.analysisId,
        analysisConfig: JSON.stringify(
          generateAnalysisConfigOfParams(["variable_y", "variable_baseline"])
        ),
      };
      showLoading();

      let res = await httpPost("/analysis/v1/saveAndRun", params);
      if (res.code == 0) {
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
