<template>
  <slot
    :prop="prop"
    :draggableValidator="validateDraggable"
    :onEnd="onEnd"
  ></slot>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, inject } from "vue";
import { httpPost } from "@/api/httpService.js";

const props = defineProps({
  prop: {
    type: String,
  },
});

const validateDraggable = inject("validateDraggable");
const onEnd = inject("onEnd");
</script>

<style lang="scss" scoped></style>
