<!-- 分类汇总 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
      :transfer-options="transferOptions"
    >
      <AnalysisConditionFormItem prop="group_variables">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <!-- {{ draggableValidator }} -->
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.group_variables"
            row="auto"
            @onEnd="onEnd"
            title="分组变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加',
                MEASURE_STANDARD.CATEGORIZATION,
                '变量1（变量数≧1）'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <AnalysisConditionFormItem prop="variables">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variables"
            row="auto"
            @onEnd="onEnd"
            title="汇总变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加',
                MEASURE_STANDARD.RATION,
                '变量2（变量数≧1）'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          类型：
          <el-select
            v-model="analysisConfig.analysis_type"
            multiple
            placeholder="请选择"
            style="width: 240px"
            autocomplete
          >
            <el-option
              v-for="item in analysisSubTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();
const emits = defineEmits(["showLoading"]);
const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const analysisSubTypeOptions = ref([
  {
    value: 1,
    label: "平均值",
  },
  {
    value: 2,
    label: "中位数",
  },
  {
    value: 3,
    label: "标准差",
  },
  {
    value: 4,
    label: "最大值",
  },
  {
    value: 5,
    label: "最小值",
  },
  {
    value: 6,
    label: "求和",
  },
]);

const libraryId = inject("libraryId");
const targetProps = ref(["variables", "group_variables"]);

const rules = ref({
  group_variables: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【分组变量】变量个数不能少于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.CATEGORIZATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.CATEGORIZATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
  variables: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【比较变量】变量个数不能少于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  generateData,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    variables: [],
    group_variables: [],
  },
  processAnalysisConditionFn: ({ res }) => {
    let originAnalysisConfig = JSON.parse(res.data.analysisConfig) || {};

    return generateData(originAnalysisConfig, targetProps.value);
  },
});
const transferOptions = ref([
  { prop: "group_variables", label: "分组变量" },
  { prop: "variables", label: "汇总变量" },
]);

const analysisConditionFormRef = ref(null);

const showLoading = () => {
  emits("showLoading");
};

const onClickStart = async () => {
  // TODO:showloading
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      if (!analysisConfig.value.analysis_type?.length) {
        proxy.message.warning("请选择类型");
      } else {
        let params = {
          libId: libraryId,
          analysisId: props.tabInfo.analysisId,
          analysisConfig: JSON.stringify({
            ...generateAnalysisConfigOfParams(),
          }),
        };
        showLoading();

        let res = await httpPost("/analysis/v1/saveAndRun", params);
        if (res.code == 0) {
        }
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
