<!-- 格兰杰因果检验 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
    >
      <AnalysisConditionFormItem prop="variables">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variables"
            row="auto"
            @onEnd="onEnd"
            title="已选变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                textBefore,
                MEASURE_STANDARD.RATION,
                '变量（变量数≧2）'
              )
            "
            tipContent="分析变量个数不能少于2。系统会检验两两数据对之间的因果关系，例如：添加3个变量，则会进行两两配对，共进行6次检验。"
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          滞后阶数：
          <el-input-number
            v-model="analysisConfig.order"
            :min="1"
            :step="1"
            :precision="0"
            controls-position="right"
          />
          <BaseTooltip>
            <template #content>
              <p style="max-width: 250px">
                滞后阶数，系统默认值是2阶，其意义在于：A变量影响到B变量，A变量不会马上影响到B变量，可能隔几期才会产生影响。关于此参数的设置，可以根据使得
                VAR 模型较优的滞后阶数来确定。
              </p>
            </template>
            <i class="iconfont icon-wenhao-tishi info-message"></i>
          </BaseTooltip>
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseTooltip from "@/components/base/BaseTooltip.vue";
import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();
const textBefore = "拖拽添加";

const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const emits = defineEmits(["showLoading"]);
// const analysisSubType1 = ref("2");

const libraryId = inject("libraryId");
const targetProps = ref(["variables"]);
// TODO:校验规则
const rules = ref({
  variables: [
    {
      min: 2,
      validateErrorCallback: () => {
        proxy.message.warning("【已选变量】变量个数不能少于2");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    variables: [],
    order: 2,
  },
});

const showLoading = () => {
  emits("showLoading");
};

const analysisConditionFormRef = ref(null);

const onClickStart = async () => {
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let params = {
        libId: libraryId,
        analysisId: props.tabInfo.analysisId,
        analysisConfig: JSON.stringify({
          ...generateAnalysisConfigOfParams(),
          // order: analysisSubType1.value,
        }),
      };
      showLoading();
      let res = await httpPost("/analysis/v1/saveAndRun", params);
      if (res.code == 0) {
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
