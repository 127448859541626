import { ref, onMounted } from "vue";

export function usePagination({
  fetchDataFn,
  pageNum = 1,
  pageSize = 10,
  total = 0,
  ifFetchOnMounted = true, // 是否在onmountd阶段fetchdata
}) {
  const _pageNum = ref(pageNum); // 当前所在页码
  const _pageSize = ref(pageSize); // 当前页面数据数量
  const _total = ref(total);
  const _tableData = ref([]);

  async function fetchData() {
    const result = await fetchDataFn();
    // _total.value = result.total;
    // _tableData.value = result.data;
  }

  const goToPage = async (page) => {
    _pageNum.value = page;
    await fetchData();
  };

  const handleSizeChange = async (size) => {
    _pageSize.value = size;
    await fetchData();
  };

  onMounted(() => {
    if (ifFetchOnMounted) {
      fetchData();
    }
  });

  return {
    pageNum: _pageNum,
    pageSize: _pageSize,
    total: _total,
    tableData: _tableData,
    goToPage,
    handleSizeChange,
  };
}
