<!-- 相关分析 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
    >
      <AnalysisConditionFormItem prop="variables">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variables"
            row="auto"
            @onEnd="onEnd"
            title="已选变量"
            :draggableValidator="draggableValidator"
            :tip="analysisStandardHtmlText"
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          <div class="analysis-subtype-item-item">
            相关系数：
            <el-select v-model="analysisConfig.method" placeholder="请选择">
              <el-option
                v-for="item in analysisSubTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <BaseTooltip class="box-item">
              <template #content>
                <p style="max-width: 250px">
                  Pearson相关系数研究定量变量之间的相关性，
                  通常要求数据满足正态分布；Spearman相关系
                  数可以研究定量变量以及有序定类变量之间的相关性，
                  不对数据分布做要求；Kendall相关系数只适用于研究
                  均为有序定类变量之间的相关性。（请注意：研究定类变量的
                  相关性时，系统只能处理数值型数据，如果不是数值型数据，请先用
                  数据编码功能将字符串类型数据转换为数值型数据）。
                </p>
              </template>
              <i class="iconfont icon-wenhao-tishi info-message"></i>
            </BaseTooltip>
          </div>
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
  computed,
  watch,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";
import BaseTooltip from "@/components/base/BaseTooltip.vue";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";
import { VARIABLE_TYPE } from "@/constant/variable_type.js";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();

const emits = defineEmits(["showLoading"]);

const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

// const analysisSubType = ref("pearson");
const analysisSubTypeOptions = ref([
  {
    value: "pearson",
    label: "Pearson相关系数",
  },
  {
    value: "spearman",
    label: "Spearman相关系数",
  },
  {
    value: "kendall",
    label: "Kendall相关系数",
  },
]);

const analysisStandardHtmlText = computed(() => {
  if (analysisConfig.value.method == "pearson") {
    return getAnalysisStandardHtmlText(
      "拖拽添加",
      MEASURE_STANDARD.RATION,
      "变量（变量数≧2）"
    );
  } else if (analysisConfig.value.method == "spearman") {
    return (
      getAnalysisStandardHtmlText("拖拽添加", MEASURE_STANDARD.RATION, "变量") +
      getAnalysisStandardHtmlText(
        "或者",
        MEASURE_STANDARD.CATEGORIZATION,
        "变量（变量数≧2且变量类型只能是数值型）"
      )
    );
  } else if (analysisConfig.value.method == "kendall") {
    return getAnalysisStandardHtmlText(
      "拖拽添加",
      MEASURE_STANDARD.CATEGORIZATION,
      "变量（变量数≧2且变量类型只能是数值型）"
    );
  }
  return null;
});

const libraryId = inject("libraryId");
const targetProps = ref(["variables"]);

const rules = ref({
  variables: [
    {
      min: 2,
      validateErrorCallback: () => {
        proxy.message.warning("【已选变量】变量个数不能少于2");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    variables: [],
    method: "pearson",
  },
});
const resetVariables = () => {
  analysisConfig.value.variables = [];
};
watch(
  () => analysisConfig.value.method,
  (newVal, oldVal) => {
    if (newVal == "pearson" && oldVal == "kendall") {
      resetVariables();
    } else if (newVal == "kendall" && oldVal == "pearson") {
      resetVariables();
    } else if (newVal == "pearson" && oldVal == "spearman") {
      resetVariables();
    } else if (newVal == "kendall" && oldVal == "spearman") {
      resetVariables();
    }
    if (newVal == "pearson") {
      rules.value.variables[1] = {
        variableStandard: [MEASURE_STANDARD.RATION],
        trigger: "move",
        moveErrorCallback: () => {
          proxy.message.warning({
            message: getAnalysisStandardHtmlText(
              "请添加",
              MEASURE_STANDARD.RATION,
              "变量"
            ),
            dangerouslyUseHTMLString: true,
          });
        },
      };
    } else if (newVal == "kendall") {
      rules.value.variables[1] = {
        variableStandard: [MEASURE_STANDARD.CATEGORIZATION],
        trigger: "move",
        moveErrorCallback: () => {
          proxy.message.warning({
            message: getAnalysisStandardHtmlText(
              "请添加",
              MEASURE_STANDARD.CATEGORIZATION,
              "变量"
            ),
            dangerouslyUseHTMLString: true,
          });
        },
      };
    } else {
      rules.value.variables[1] = {};
    }
  },
  { immediate: true, deep: true }
);

const analysisConditionFormRef = ref(null);

// const onEnd = ref(null);
// onMounted(() => {
//   onEnd.value = analysisConditionFormRef.value.onEnd;
// });

const showLoading = () => {
  emits("showLoading");
};

const onClickStartAnalysis = (params) => {
  let res = analysisConfig.value.variables.find(
    (element) => element.type != VARIABLE_TYPE.NUMBER
  );
  if (res) {
    proxy.message.warning("变量类型只能是数值型");
  }
  return res;
};

const onClickStart = async () => {
  // TODO:showloading
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let params = {
        libId: libraryId,
        analysisId: props.tabInfo.analysisId,
        analysisConfig: JSON.stringify({
          ...generateAnalysisConfigOfParams(),
          // method: analysisConfig.value,
        }),
      };
      showLoading();
      let check = onClickStartAnalysis(params);
      if (!check) {
        let res = await httpPost("/analysis/v1/saveAndRun", params);
        if (res.code == 0) {
        }
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
