<!-- 灰色关联分析 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
      :transfer-options="transferOptions"
    >
      <AnalysisConditionFormItem prop="characteristic_sequence_variables">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.characteristic_sequence_variables"
            row="auto"
            @onEnd="onEnd"
            title="特征序列"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加特征序列',
                MEASURE_STANDARD.RATION,
                '变量（变量数≧2）'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <AnalysisConditionFormItem prop="reference_sequence_variable">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.reference_sequence_variable"
            row="1"
            @onEnd="onEnd"
            title="母序列"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加母序列',
                MEASURE_STANDARD.RATION,
                '变量（变量数=1)'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <AnalysisConditionFormItem prop="index_item">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.index_item"
            row="1"
            @onEnd="onEnd"
            title="索引项"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加索引项',
                MEASURE_STANDARD.CATEGORIZATION,
                '变量（变量数≤1)'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          <div class="analysis-subtype-item-item">
            无量纲处理方式：
            <el-select
              style="width: 120px"
              v-model="analysisSubType1"
              placeholder="请选择"
            >
              <el-option
                v-for="item in analysisSubTypeOptions1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <BaseTooltip>
              <template #content>
                <p style="max-width: 250px">
                  初值化：初值化处理即把序列第一个数据除以该序列所有数据，
                  得到一个新数列。均值化：对序列的每一个数据都除以均值，
                  得到一个新数列。初值化方法适用于稳定递增或递减的数据，
                  而均值化适合没有明显升降趋势现象的数据。
                </p>
              </template>
              <i class="iconfont icon-wenhao-tishi info-message"></i>
            </BaseTooltip>
          </div>
          <div class="analysis-subtype-item-item">
            <span>分辨系数ρ：</span>
            <el-input-number
              v-model="analysisSubType2"
              :min="0"
              :max="1"
              :step="0.1"
              controls-position="right"
            />
            <BaseTooltip>
              <template #content>
                <p style="max-width: 250px">
                  浮点数，分辨系数
                  ρ∈(0,1)，ρ越小，分辨力越大，具体取值可视情况而定。 当 ρ ≤
                  0.5463时，分辨力最好，通常取 ρ = 0.5。
                </p>
              </template>
              <i class="iconfont icon-wenhao-tishi info-message"></i>
            </BaseTooltip>
          </div>
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseTooltip from "@/components/base/BaseTooltip.vue";
import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();

const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const emits = defineEmits(["showLoading"]);
const analysisSubType1 = ref("null");
const analysisSubTypeOptions1 = ref([
  {
    value: "mean",
    label: "均值化",
  },
  {
    value: "initial",
    label: "初值化",
  },
  {
    value: "null",
    label: "不处理",
  },
]);

const analysisSubType2 = ref("0.5");

const libraryId = inject("libraryId");
const targetProps = ref([
  "characteristic_sequence_variables",
  "reference_sequence_variable",
  "index_item",
]);

const transferOptions = ref([
  { prop: "characteristic_sequence_variables", label: "特征序列" },
  { prop: "reference_sequence_variable", label: "母序列" },
  { prop: "index_item", label: "索引项" },
]);
// TODO:校验规则
const rules = ref({
  characteristic_sequence_variables: [
    {
      min: 2,
      validateErrorCallback: () => {
        proxy.message.warning("【特征序列】变量个数不能少于2");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
  reference_sequence_variable: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【母序列】变量个数不能少于1");
      },
    },
    {
      max: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【母序列】变量个数不能大于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
  index_item: [
    {
      max: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【索引项】变量个数不能大于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.CATEGORIZATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.CATEGORIZATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    characteristic_sequence_variables: [],
    reference_sequence_variable: [],
    index_item: [],
  },
});

const showLoading = () => {
  emits("showLoading");
};

const analysisConditionFormRef = ref(null);

const onClickStart = async () => {
  // TODO:showloading
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let params = {
        libId: libraryId,
        analysisId: props.tabInfo.analysisId,
        analysisConfig: JSON.stringify({
          ...generateAnalysisConfigOfParams([
            "reference_sequence_variable",
            "index_item",
          ]),
          dimensionless_method: analysisSubType1.value,
          recognition: analysisSubType2.value,
        }),
      };
      showLoading();
      let res = await httpPost("/analysis/v1/saveAndRun", params);
      if (res.code == 0) {
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
