<template>
  <div class="var-edit-dialog">
    <!-- 编辑的弹框 -->
    <el-dialog
      class="base-dialog"
      v-model="DLGVisible"
      :show-close="true"
      :close-on-click-modal="false"
      width="36%"
    >
      <template #header>
        <BaseDialogTitle
          :title="DLGTitle"
          :itemName="itemName"
        ></BaseDialogTitle>
      </template>
      <el-form
        :model="varForm"
        :rules="varFormRules"
        ref="varFormRef"
        label-width="80px"
        @submit.prevent
      >
        <div class="col">
          <div class="left-side">
            <el-form-item label="变量名称：" prop="colname" required>
              <el-input
                clearable
                placeholder="请填写"
                v-model.trim="varForm.colname"
              ></el-input>
            </el-form-item>
            <el-form-item label="变量类型：" prop="type">
              <el-select
                v-model="varForm.type"
                placeholder="请选择"
                style="width: 100%"
                @change="handleChangeType"
              >
                <el-option
                  v-for="(item, index) in VARIABLE_TYPE_LIST"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="宽度：" prop="len" v-show="ifLenDisabled">
              <el-input-number
                v-model="varForm.len"
                :min="1"
                :max="VARIABLE_MAP[varForm.type].maxLen"
                controls-position="right"
                style="min-width: 50%"
              />
            </el-form-item>
            <el-form-item
              label="日期格式："
              prop="len"
              v-show="ifDateFormatDisabled"
            >
              <el-select
                v-model="varForm.dateFormat"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in DATE_FORMAT_LIST"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="right-side">
            <el-form-item label="变量标签：" prop="collabel" right>
              <el-input
                clearable
                placeholder="请填写"
                v-model.trim="varForm.collabel"
              ></el-input>
            </el-form-item>

            <el-form-item label="度量标准：" prop="standard">
              <el-select
                v-model="varForm.standard"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in MEASURE_STANDARD_LIST"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                  :disabled="
                    item.value == MEASURE_STANDARD.RATION && ifRationDisabled
                  "
                >
                  <BaseTooltip
                    class="box-item"
                    content="字符串类型的变量不能设置为定量"
                    v-if="
                      item.value == MEASURE_STANDARD.RATION && ifRationDisabled
                    "
                  >
                    {{ item.label }}
                  </BaseTooltip>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="小数位数："
              prop="decimal"
              v-show="ifDecimalDisabled"
            >
              <el-input-number
                v-model="varForm.decimal"
                controls-position="right"
                S
                style="min-width: 50%"
              />
            </el-form-item>
          </div>
        </div>

        <el-form-item label="值：" prop="colvalue" v-show="ifColvalueDisabled">
          <div class="colvalue">
            <el-input
              class="colvalue-input"
              placeholder="请设置值标签"
              v-model="colValueString"
              @input="handleChangeColValue"
            ></el-input>
            <i
              class="iconfont icon-a-zhibiaoqianshezhi-bianliangguanlibeixuan-copy operate-num"
              @click="handleClickColValueSetting"
            ></i>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClickCancel">取消</el-button>
          <el-button type="primary" @click="handleClickSave">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <ColValueDLGManage
    ref="colValueDLGManageRef"
    :item-name="itemName"
    :lib-id="libraryId"
    :variableId="varForm.id"
    @save="onSaveColValue"
  ></ColValueDLGManage>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  defineExpose,
  computed,
  defineEmits,
  defineProps,
} from "vue";

import { httpPost } from "@/api/httpService.js";

import { CATEGORIZED_NUMBER_THAN_ONE_HUNDRED } from "@/constant/error_code.js";
import {
  VARIABLE_TYPE,
  VARIABLE_MAP,
  VARIABLE_TYPE_LIST,
} from "@/constant/variable_type.js";
import {
  MEASURE_STANDARD,
  MEASURE_STANDARD_LIST,
  getColValueString,
} from "@/constant/variable_standard.js";
import { DATE_FORMAT_LIST, DATEDEFAULT } from "@/constant/date_format.js";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import BaseTooltip from "@/components/base/BaseTooltip.vue";

import ColValueDLGManage from "./ColValueDLGManage.vue";
import { ElMessageBox } from "element-plus";

const props = defineProps({
  itemName: {
    type: String,
  },
});

const { proxy } = getCurrentInstance();
const emits = defineEmits(["refresh"]);
const DLGVisible = ref(false);
const DLGTitle = ref("变量编辑");
//数据集的名称
// const DLGItemName = ref("");
const varForm = ref({});
const varFormRef = ref(null);
const varFormRules = {
  colname: [{ required: true, message: "变量名称不能为空", trigger: "change" }],
};
const libraryId = ref(null);

const colValueString = ref("");

//宽度的显示，变量类型为数值、字符串
const ifLenDisabled = computed(() => {
  if (varForm.value.type !== VARIABLE_TYPE.DATE) {
    return true;
  } else {
    return false;
  }
});
//小数位数的显示 变量类型为数值
const ifDecimalDisabled = computed(() => {
  if (varForm.value.type == VARIABLE_TYPE.NUMBER) {
    return true;
  } else {
    return false;
  }
});
//日期格式 变量类型为日期
const ifDateFormatDisabled = computed(() => {
  if (varForm.value.type == VARIABLE_TYPE.DATE) {
    return true;
  } else {
    return false;
  }
});
//值标签的显示隐藏 度量标准为定类
const ifColvalueDisabled = computed(() => {
  if (
    varForm.value.standard == MEASURE_STANDARD.CATEGORIZATION &&
    varForm.value.type == VARIABLE_TYPE.NUMBER
  ) {
    return true;
  } else {
    return false;
  }
});

//当变量类型为字符的时候，定量是不可选的
const ifRationDisabled = computed(() => {
  if (varForm.value.type == VARIABLE_TYPE.STRING) {
    return true;
  } else {
    return false;
  }
});

const originStandard = ref("");

const handleChangeType = (value) => {
  if (value == VARIABLE_TYPE.STRING) {
    varForm.value.standard = MEASURE_STANDARD.CATEGORIZATION;
  }
  if (value == VARIABLE_TYPE.DATE) {
    varForm.value.dateFormat = DATEDEFAULT;
  }
  varForm.value.decimal = VARIABLE_MAP[value].decimal;
  varForm.value.len = VARIABLE_MAP[value].maxLen;
};
//初始化的弹框数据
const init = ({ data, id }) => {
  varForm.value = JSON.parse(JSON.stringify(data));
  varForm.value._originType = varForm.value.type;
  libraryId.value = id;
  colValueString.value = getColValueString(data.colvalue);
  originColValueString = colValueString.value;
  originStandard.value = varForm.value.standard;
};
let originColValueString = "";
const handleChangeColValue = () => {
  colValueString.value = originColValueString;
};

const onSaveColValue = (colValue) => {
  varForm.value.colvalue = colValue;
  varForm.value.editedColValue = colValue;

  colValueString.value = getColValueString(colValue);
  originColValueString = colValueString.value;
};

const openDLG = (params) => {
  DLGVisible.value = true;
  // DLGItemName.value = dataSetName;
  init(params);
};
const handleClickCancel = () => {
  DLGVisible.value = false;
  varFormRef.value.resetFields();
};

//当转换为定类的时候，进行校验分类得到数目
const checkClassifyAmount = async () => {
  let params = {
    libId: libraryId.value,
    variableInfo: varForm.value,
  };
  let res = await httpPost("/lib/variable/v1/update/check", params);
  if (res.code == CATEGORIZED_NUMBER_THAN_ONE_HUNDRED) {
    await proxy.confirm("分类数目超过100个，是否继续转换度量标准？");
    // , async () => {
    await updateVar();
    // });
  } else {
    await updateVar();
  }
};
const updateVar = async () => {
  let params = {
    libId: libraryId.value,
    variableInfo: varForm.value,
  };
  if (varForm.value.standard == MEASURE_STANDARD.RATION) {
    params.variableInfo.colvalue = null;
  }
  let res = await httpPost("/lib/variable/v1/update", params);
  if (res.code == 0) {
    proxy.message.success("更新成功");
  }
  return res;
  // console.log("updateVar", dlgVisible);
  // DLGVisible.value = dlgVisible;
  // emits("refresh");
};

const saveVariable = async () => {
  let valid = await varFormRef.value.validate();
  if (!valid) {
    return;
  }

  if (
    varForm.value.standard == MEASURE_STANDARD.RATION &&
    varForm.value.colvalue !== null
  ) {
    await proxy.confirm("转换为定量变量，将丢失之前的值标签");
    await updateVar();
  } else if (
    originStandard.value != MEASURE_STANDARD.CATEGORIZATION &&
    varForm.value.standard == MEASURE_STANDARD.CATEGORIZATION
  ) {
    await checkClassifyAmount();
  } else {
    await updateVar();
  }
};
const handleClickSave = async () => {
  //转换为定量，并且之前有值标签

  await saveVariable();
  emits("refresh", varForm.value);

  DLGVisible.value = false;
};

// 值标签管理弹窗
const colValueDLGManageRef = ref(null);
const openColValueDLGManage = () => {
  colValueDLGManageRef.value.openDLG({
    colValue: varForm.value.editedColValue,
  });
};
const handleClickColValueSetting = () => {
  if (varForm.value._originType == VARIABLE_TYPE.STRING) {
    ElMessageBox.confirm("是否确认转换变量类型?", "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      saveVariable();
      openColValueDLGManage();
    });
  } else {
    openColValueDLGManage();
  }
};
defineExpose({
  openDLG,
  init,
  handleClickSave,
});
</script>

<style lang="scss" scoped>
.var-edit-dialog {
  .colvalue {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .colvalue-input {
      flex: 1;
      :deep(.el-input__wrapper.is-focus) {
        // border-color: transparent;
        box-shadow: 0 0 0 1px #dcdfe6 inset;
      }
      :deep(.el-input__wrapper:hover) {
        // border-color: transparent;
        box-shadow: 0 0 0 1px #dcdfe6 inset;
      }
    }
    .operate-num {
      font-size: 35px;
      color: rgba(220, 220, 220, 100);
      cursor: pointer;
      margin-left: 15px;
    }
    .operate-num:hover {
      color: $theme-color;
    }
  }
  .col {
    display: flex;
    justify-content: space-between;
    .left-side {
      width: 45%;
    }
    .right-side {
      width: 45%;
      display: flex;
      flex-direction: column;
    }
  }
}
:deep(.el-dialog) {
  min-width: 600px;
  max-width: 700px;
  .el-dialog__body {
    padding: 30px 20px !important;
  }
}

:deep(.el-form-item__label) {
  padding: 0;
}
</style>
