<template>
  <div
    class="transfer-wrapper"
    :style="{
      height: height,
      'flex-grow': 1,
      overflow: row == 'auto' ? 'auto' : '',
    }"
  >
    <div v-if="title" class="transfer-title">{{ title }}</div>
    <BaseTooltip :content="tip" raw-content>
      <div class="transfer-box" :style="{ 'min-height': minHeight }">
        <div v-show="variableList.length == 0" class="content-tip">
          <div
            class="tip"
            v-html="tip"
            :style="{ 'font-size': tipFontSize }"
          ></div>
          <BaseTooltip v-if="tipContent">
            <template #content>
              <p style="max-width: 250px">{{ tipContent }}</p>
            </template>
            <i class="iconfont icon-wenhao-tishi"></i>
          </BaseTooltip>
        </div>
        <draggable
          :list="variableList"
          ghost-class="ghost"
          chosen-class="chosenClass"
          animation="0"
          @start="onStart"
          :move="onMove"
          @end="onEnd"
          group="variableGroup"
          item-key="id"
          style="height: 100%"
          :id="name"
        >
          <template #item="{ element }">
            <div class="variable-item">
              <VariableTransferItem
                :data="element"
                :active="element.active"
                :removable="true"
                @deleteVar="handleDeleteVar"
              ></VariableTransferItem>
            </div>
          </template>
        </draggable>
      </div>
    </BaseTooltip>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, computed, watch } from "vue";
import { httpPost } from "@/api/httpService.js";
import BaseTooltip from "@/components/base/BaseTooltip.vue";

import VariableTransferItem from "./VariableTransferItem.vue";
import draggable from "vuedraggable";

const props = defineProps({
  title: {
    type: String,
    default: () => "已选变量",
  },
  /* 控制行数
     row为数字时代表下方显示的行数高度，
    为‘auto’时代表自动填充剩余部分高度，
    不填写时为自适应高度*/
  row: {
    type: [String, Number],
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  draggableValidator: {
    default: () => null,
  },
  // 用于定位onend移入了谁
  name: {
    type: String,
  },
  // 校验提示
  tip: {
    type: String,
  },
  // 校验提示的详细内容
  tipContent: {
    type: String,
  },
  // 提示字体大小
  tipFontSize: {
    type: String,
    default: () => {
      return "14px";
    },
  },
});

const emits = defineEmits(["update:modelValue", "onEnd"]);

const variableList = ref([]);
// 监听modelValue 并修改varaibleList的值
watch(
  () => props.modelValue,
  (newVal) => {
    if (Array.isArray(newVal)) {
      variableList.value = newVal;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

const handleDeleteVar = (variableId) => {
  variableList.value = variableList.value.filter((element) => {
    return element.id != variableId;
  });
  emits("update:modelValue", variableList.value);
};

const onStart = (...args) => {};
const onEnd = (event) => {
  // emits("update:modelValue", variableList.value);
  emits("onEnd", event);
};

const onMove = (event) => {
  let res = props.draggableValidator(event);
  return res;
  // if (!res.valid) {
  //   if(res.rule.moveErrorCallback)
  //   return false;
  // }
  // return true;
};

const titleHeight = 40; //标题高度
const padding = 5; //内部padding
const rowHeight = 30; // 行高
// 根据row属性计算容器高度
const height = computed(() => {
  return Number(props.row)
    ? `${(rowHeight + padding) * props.row + titleHeight + padding + 1}px`
    : "100%";
});
const minHeight = computed(() => {
  return rowHeight * props.row + padding * 2 + 1 + "px";
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/variable-transfer.scss";
</style>
