<template>
  <div>
    <el-dialog
      class="base-dialog"
      v-model="DLGVisible"
      :title="DLGTitle"
      :before-close="closeDLG"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <template #header>
        <BaseDialogTitle
          :title="DLGTitle"
          :item-name="itemName"
        ></BaseDialogTitle>
      </template>
      <div v-show="viewType == 'ITEM'">
        <el-form
          ref="formRef"
          :model="colValueForm"
          class="col-value-form"
          v-loading="formLoading"
        >
          <div class="col-value-box" style="height: 36px">
            <el-form-item class="col-value-key">数值</el-form-item>
            <el-form-item class="col-value-label">标签</el-form-item>
          </div>
          <div
            class="col-value-box"
            v-for="(colValueItem, colValueIndex) in colValueForm"
            :key="colValueIndex"
          >
            <el-form-item
              :prop="[colValueIndex, 'key']"
              :rules="colValueKeyRules"
              class="col-value-key"
            >
              <!-- {{ colValueItem }} -->
              <el-input
                v-model="colValueItem.key"
                type="number"
                placeholder="请输入数值"
                @input="handleChangeColValueKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              :prop="[colValueIndex, 'label']"
              :rules="colValueLabelRules"
              class="col-value-label"
            >
              <!-- {{ colValueItem.label }} -->
              <el-input
                v-model.trim="colValueItem.label"
                @input="handleChangeColValueLabel"
                placeholder="请输入数字代表意义"
              ></el-input>
            </el-form-item>
            <el-button
              link
              style="height: 28px; margin-left: 20px"
              @click="handleClickRemoveColValue(colValueIndex)"
              v-show="colValueForm.length > 1"
            >
              <i class="iconfont icon-huishouzhan"></i>
            </el-button>
          </div>
        </el-form>
        <div class="button-group">
          <el-button link type="primary" @click="handleClickAddColValue">
            <i class="iconfont icon-tianjia-bianliangguanli-zhibiaoqian"></i>
            添加
          </el-button>
          <el-button link type="primary" @click="handleClickBatchEdit">
            <i class="iconfont icon-piliangbianji"></i>
            批量编辑
          </el-button>
        </div>
      </div>
      <div class="batch-edit-wrapper" v-show="viewType == 'BATCH'">
        <el-input
          type="textarea"
          :rows="10"
          v-model="batchInputValue"
        ></el-input>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDLG">取消</el-button>
          <el-button type="primary" @click="handleClickConfirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  computed,
  nextTick,
  getCurrentInstance,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { Debounce } from "@/utils/utils";

const { proxy } = getCurrentInstance();

const emits = defineEmits(["save"]);
const props = defineProps({
  itemName: {
    type: String,
  },
  libId: {
    type: [String, Number],
  },
  variableId: {
    type: String,
  },
});

// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("值标签设置");

const openDLG = (params) => {
  getColValueList(params.colValue);
  DLGVisible.value = true;
};

// const colValueForm = ref({ 1: 1, 3: 3 });

const closeDLG = () => {
  DLGVisible.value = false;
  viewType.value = "ITEM";
  resetForm();
};
const translateColValueStringToForm = () => {
  let colValueStringList = batchInputValue.value.split("\n");
  colValueForm.value = [];

  colValueStringList.forEach((colValueString) => {
    let [key, ...label] = colValueString.split(":");
    if (key.trim() && !isNaN(key.trim())) {
      colValueForm.value.push({ key: key, label: label.join() });
    }
  });
};
// 点击确认
const handleClickConfirm = () => {
  if (viewType.value == "BATCH") {
    translateColValueStringToForm();
  }

  formRef.value
    .validate()
    .then((res) => {
      emits("save", getColValueMap(colValueForm.value));
      closeDLG();
    })
    .catch(() => {
      if (viewType.value == "BATCH") {
        proxy.message.warning("数值/标签重复，请重新输入");
      }
    });
};
const getColValueMap = (colValueForm) => {
  let map = {};
  colValueForm.forEach((colValue) => {
    map[colValue.key] = colValue.label;
  });
  return map;
};

// 表格相关
//变量值标签表单数据
const colValueForm = ref([{ key: null, label: null }]);
const formLoading = ref(true);
// 获取值标签列表
const getColValueList = async (colValueMap) => {
  formLoading.value = true;

  if (colValueMap) {
    colValueForm.value = [];
    for (let key in colValueMap) {
      colValueForm.value.push({ key: key, label: colValueMap[key] });
    }
  } else {
    let params = {
      libId: props.libId,
      variableId: props.variableId,
    };

    let res = await httpPost(
      "/lib/variable/v1/getColvalueByLibIdAndVariableId",
      params
    );
    if (res.code == 0) {
      colValueForm.value = res.data.colvalueList;
    }
  }

  formLoading.value = false;
};
const formRef = ref(null);

// 值标签数值的出现次数map，辅助检测是否有重复数值
const colValueKeyMap = computed(() => {
  let resultMap = {};
  colValueForm.value.forEach((item) => {
    let key = Number(item.key);
    if (String(item.key).length > 0) {
      if (resultMap[key]) {
        resultMap[key] += 1;
      } else {
        resultMap[key] = 1;
      }
    }
  });
  return resultMap;
});
// 值标签label的出现次数map，辅助检测是否有重复label
const colValueLabelMap = computed(() => {
  let resultMap = {};
  colValueForm.value.forEach((item) => {
    let label = String(item.label || "");
    if (label.length > 0) {
      if (resultMap[label]) {
        resultMap[label] += 1;
      } else {
        resultMap[label] = 1;
      }
    }
  });
  return resultMap;
});

//检查是否有重复数值
const checkColValueKey = (rule, value, callback) => {
  if (colValueKeyMap.value[Number(value)] > 1) {
    callback(new Error("数值重复，请重新输入"));
  } else {
    callback();
  }
};
//检查是否有重复标签
const checkColValueLabel = (rule, value, callback) => {
  if (colValueLabelMap.value[value] > 1) {
    callback(new Error("标签重复，请重新输入"));
  } else {
    callback();
  }
};
const colValueKeyRules = reactive([
  {
    validator: checkColValueKey,
    trigger: "change",
  },
  {
    required: true,
    trigger: "change",
    message: "请填写数值",
  },
]);
const colValueLabelRules = reactive([
  {
    validator: checkColValueLabel,
    trigger: "change",
  },
]);

const handleChangeColValueKey = () => {
  formRef.value.validate().then((res) => {});
};
const handleChangeColValueLabel = () => {
  formRef.value.validate();
};

const handleClickAddColValue = () => {
  colValueForm.value.push({ key: "", label: null });
  nextTick(() => {
    let dom = document.getElementsByClassName("col-value-form")[0];
    dom.scrollTop = dom.scrollHeight;
  });
};

const viewType = ref("ITEM"); // 逐条编辑ITEM；批量编辑BATCH
const handleClickBatchEdit = () => {
  changeToBatchView();
};

const batchInputValue = ref("");
const changeToBatchView = () => {
  viewType.value = "BATCH";
  batchInputValue.value = "";
  let result = "";
  colValueForm.value.forEach((colValue) => {
    result += colValue.key + ":" + (colValue.label || "") + "\n";
  });
  batchInputValue.value = result;
};

const handleClickRemoveColValue = (index) => {
  colValueForm.value.splice(index, 1);
};

// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
};

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
  .el-dialog__body {
    padding: 24px 0px;
    padding-bottom: 6px;
  }
}
.col-value-form::-webkit-scrollbar {
  width: 5px; //竖向滚动条
  height: 5px; //横向滚动条
}
/* 滚动槽 */
.col-value-form::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
/* 滚动条滑块 */
.col-value-form::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #dcdcdc;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5);
}
.col-value-form::-webkit-scrollbar-thumb:window-inactive {
  // background: rgba(255, 0, 0, 0.4);
}
.col-value-form {
  max-height: calc(100vh - 15vh - 300px);
  overflow: auto;
  padding: 0px 40px;
  margin-bottom: 24px;
  scroll-behavior: smooth; /* 添加滚动动画效果 */

  /* 设置滚动条的样式 */

  .col-value-box {
    display: flex;
    .col-value-key {
      width: 130px;
      margin-right: 36px;
      :deep(.el-input__wrapper) {
        padding-right: 2px;
      }
    }
    .col-value-label {
      width: 280px;
      // display: flex;
    }
  }
}
.button-group {
  padding: 0px 40px;
  .iconfont {
    margin-right: 8px;
  }
}
</style>
