<template>
  <div
    class="variable-transfer-item"
    :style="{
      height: `${height}px`,
      background: isHover || active ? activeColor : inactiveColor,
    }"
    @mouseenter="handleMouseEnter(data)"
    @mouseleave="handleMouseLeave(data)"
    @click="handleClickItem"
  >
    <span class="variable-transfer-text">
      <BaseTooltip :content="data.colname">
        <span>
          {{ data.colname }}
        </span>
      </BaseTooltip>
    </span>
    <span class="variable-transfer-tag">
      <span>
        <StandardTag :type="data.standard" size="small"></StandardTag>
      </span>
      <el-dropdown
        class="more-operation"
        @command="handleMoreOptions"
        @mouseenter="handleMouseEnter(data)"
        @mouseleave="handleMouseLeave(data)"
        v-if="!removable"
      >
        <div class="icon-message">
          <!-- TODO: 编辑删除功能按钮 -->
          <i
            v-if="isHover"
            class="iconfont icon-gengduo"
            style="color: #1a78ff"
          ></i>
        </div>
        <!-- TODO: 编辑删除功能 -->
        <template #dropdown>
          <el-dropdown-menu
            @mouseenter="handleMouseEnter(data)"
            @mouseleave="handleMouseLeave(data)"
          >
            <el-dropdown-item command="edit">编辑</el-dropdown-item>
            <el-dropdown-item command="delete">删除</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <i
        v-else-if="removable"
        class="iconfont icon-chahao"
        :style="{ color: isHover ? '#1a78ff' : '' }"
        style="font-size: 14px"
        @click="handleSelectVarDelete"
      ></i>
    </span>
  </div>
  <!-- 修改变量的弹框 -->
  <VarEditDLG
    ref="VarEditDLGref"
    :item-name="libraryName"
    @refresh="updateVariableList"
  ></VarEditDLG>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  getCurrentInstance,
} from "vue";

import { httpPost } from "@/api/httpService.js";
import { MEASURE_STANDARD_MAP } from "@/constant/variable_standard.js";
import StandardTag from "@/components/base/StandardTag.vue";
import BaseTooltip from "@/components/base/BaseTooltip.vue";
import VarEditDLG from "@/views/project/components/VariableManagementDLGEdit.vue";
const { proxy } = getCurrentInstance();

const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {
        id: "",
        colname: "",
        standard: "",
      };
    },
  },
  // 行高
  height: {
    type: [String, Number],
    default: () => 30,
  },
  // 是否选中
  active: {
    type: Boolean,
    default: () => false,
  },
  activeColor: {
    type: String,
    default: () => "rgba(26, 120, 255, 0.05)",
  },
  inactiveColor: {
    type: String,
    default: () => "rgba(239, 239, 239, 0.6)",
  },
  isSelected: {
    type: Boolean,
    default: () => false,
  },
  removable: {
    type: Boolean,
    default: () => false,
  },
});
const emits = defineEmits(["deleteVar", "updateVar", "click"]);

const handleMoreOptions = (command) => {
  switch (command) {
    case "edit":
      handleVarEdit();
      break;
    case "delete":
      handleVarDelete();
      break;
  }
};
const isHover = ref(false);
const handleMouseEnter = (data) => {
  isHover.value = true;
};
const handleMouseLeave = (data) => {
  isHover.value = false;
};

const handleClickItem = (event) => {
  emits("click", event);
};

const libraryId = inject("libraryId");
const libraryName = ref("");
const getDataRecordTitle = async () => {
  let params = {
    id: libraryId,
  };
  let res = await httpPost(`/lib/v1/getDetail`, params);
  if (res.code == 0) {
    libraryName.value = res.data.name;
  }
};
// 变量编辑
const VarEditDLGref = ref(null);
const handleVarEdit = () => {
  getDataRecordTitle();
  VarEditDLGref.value.openDLG({ data: props.data, id: libraryId });
};
const updateVariableList = (varForm) => {
  emits("updateVar", varForm);
};
// 变量删除
const handleVarDelete = () => {
  proxy.confirm(
    "变量删除后，数据集里的该变量的数据会全部清空，是否确认删除？",
    async () => {
      let params = {
        libId: libraryId, //数据集id
        variableId: props.data.id,
      };
      let res = await httpPost("/lib/variable/v1/delete", params);
      if (res.code == 0) {
        proxy.message.success("删除成功");
        emits("deleteVar", props.data.id);
      } else {
        proxy.message.error("删除失败");
      }
    }
  );
};

// 已选变量删除
const handleSelectVarDelete = () => {
  emits("deleteVar", props.data.id);
};
</script>

<style lang="scss" scoped>
.variable-transfer-item {
  background-color: rgba(239, 239, 239, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 16px;
  border-radius: 4px;
  color: rgba(16, 16, 16, 1);
  cursor: pointer;
  .variable-transfer-text {
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .variable-transfer-tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .more-operation {
      width: 18px;
      height: 18px;
    }
    i {
      padding-left: 5px;
    }
    .icon-chahao {
      color: #bebebe;
    }
  }
}
// .variable-transfer-item:hover {
//   background-color: rgba(26, 120, 255, 0.05);
//   .icon-gengduo {
//     color: #1a78ff;
//     display: block;
//   }
//   .icon-chahao {
//     color: #1a78ff;
//   }
// }
// .more-operation:hover {
//   .icon-gengduo {
//     display: block;
//     color: #1a78ff;
//   }
// }
</style>
