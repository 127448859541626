import { ref, watch, provide } from "vue";

// props为组件接受的props，包含analysisConfig、allVariableList、targetProps
export function useVariableTransfer(props) {
  //变量来源
  const sourceVariableList = ref([]);

  //根据全部变量列表和targetprops生成sourceVariablesList;
  const generateSourceVariableList = (
    allVariableList,
    analysisConfig,
    targetProps
  ) => {
    let usedVariableIdList = []; // 被使用的属性列表（包含重复元素）
    targetProps.forEach((prop) => {
      return analysisConfig?.[prop]?.map((item) => {
        if (typeof item == "string") {
          usedVariableIdList.push(item);
        } else {
          usedVariableIdList.push(item.id);
        }
      });
    });
    // ("used colanme list", usedVariableIdList);
    return allVariableList.filter(
      (variable) => !usedVariableIdList.includes(variable.id)
    );
  };
  watch(
    () => props,
    (newVal, oldVal) => {
      if (newVal) {
        // formData.value = JSON.parse(JSON.stringify(newVal.analysisConfig));
        //TODO
        sourceVariableList.value = generateSourceVariableList(
          JSON.parse(JSON.stringify(newVal.allVariableList)),
          newVal.analysisConfig,
          newVal.targetProps
        );
      }
    },
    {
      immediate: true,
      deep: true,
    }
  );

  // 拖拽完成时触发的校验函数
  const onEnd = (event) => {
    if (ifMoveToTarget) {
      ifMoveToTarget = false;
      return;
    }
    ifMoveToTarget = false;
    if (moveErrorCallback instanceof Function) {
      moveErrorCallback();
    }
    moveErrorCallback = null;
  };
  provide("onEnd", onEnd);

  // 松开后的函数
  const onUnchoose = (...args) => {};
  // 表单的校验方法，会返回
  const validate = async () => {
    const promises = [];
    for (let prop in props.rules) {
      promises.push(validateProp(prop));
    }
    const res = await Promise.all(promises);
    let result = {
      valid: true, //是否通过校验
      invalidList: [], // 不合法的结果列表
    };
    res.forEach((fieldResult) => {
      fieldResult?.forEach((item) => {
        if (item?.valid == false) {
          result.valid = false;
          // item?.rule?.validateErrorCallback();
          result.invalidList.push(item);
        }
      });
    });
    return result;
  };
  //校验单个属性
  const validateProp = (prop) => {
    const value = props.analysisConfig[prop];
    const rules = props.rules[prop];

    return new Promise((resolve, reject) => {
      const tasks = [];
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        const { min, max, validator, variableType, trigger } = rule;

        if (min) {
          tasks.push(validateMin(prop, value, rule));
        }

        if (max) {
          tasks.push(validateMax(prop, value, rule));
        }

        if (validator) {
          tasks.push(validator(prop, value, rule));
        }
        if (variableType) {
          tasks.push(validateVariableType(prop, value, rule));
        }
      }

      Promise.all(tasks)
        .then((res) => {
          resolve([...res]);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  };

  // 校验数量最小值
  const validateMin = (prop, value, rule) => {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(value)) {
        reject({ valid: false, message: "不是数组" });
      }
      const { min } = { ...rule };
      if (value.length < min) {
        resolve({ valid: false, rule });
      } else {
        resolve({ valid: true, rule });
      }
    });
  };
  // 校验数量最大值
  const validateMax = (prop, value, rule) => {};
  // 校验变量类型
  const validateVariableType = (prop, value, rule) => {};
  // 校验自定义validator
  const validateCustom = (prop, value, rule) => {};

  // 移动时校验移动后是否超出给定max
  const validateMaxOnMove = (prop, rule, movedVariableList) => {
    let movedLength = movedVariableList.length; //默认移动的元素个数为1

    const length = props.analysisConfig[prop].length;
    return {
      valid: length + movedLength <= rule.max,
      rule,
    };
  };

  // 检查变量类型
  const validateVariableTypeOnMove = (prop, rule, movedVariableList) => {
    let { variableType } = { ...rule };
    // 统一将单个变量类型修改为变量类型列表
    if (!Array.isArray(variableType)) {
      variableType = [variableType];
    }

    for (let variable of movedVariableList) {
      if (!variableType.includes(variable.type)) {
        return {
          valid: false,
          rule,
        };
      }
    }
    return {
      valid: true,
      rule,
    };
  };

  // 检查变量标准
  const validateVariableStandardOnMove = (prop, rule, movedVariableList) => {
    let { variableStandard } = { ...rule };

    if (typeof variableStandard === "function") {
      variableStandard = variableStandard();
    }
    // 统一将单个变量类型修改为变量类型列表
    if (!Array.isArray(variableStandard)) {
      variableStandard = [variableStandard];
    }

    for (let variable of movedVariableList) {
      if (!variableStandard.includes(variable.standard)) {
        return {
          valid: false,
          rule,
        };
      }
    }
    return {
      valid: true,
      rule,
    };
  };

  /* 移动变量时出发校验
   * @param{string} prop 目标prop
   * @param{Object} movedVariable 被移动的变量
   *
   */
  const validatePropOnMove = (prop, movedVariable, callback) => {
    const rules = props?.rules?.[prop] || [];
    // return new Promise((resolve, reject) => {
    //   const tasks = [];
    let validResult = {
      valid: true, // 默认校验成功
    };
    // 统一处理被移动的变量为被移动变量列表
    if (!Array.isArray(movedVariable)) {
      movedVariable = [movedVariable];
    }
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];
      const { max, validatorOnMove, variableType, trigger, variableStandard } =
        rule;
      // 如果校验规则不是move则跳过
      if (trigger != "move") {
        continue;
      }

      if (max) {
        validResult = validateMaxOnMove(prop, rule, movedVariable);
      } else if (variableType) {
        validResult = validateVariableTypeOnMove(prop, rule, movedVariable);
      } else if (variableStandard) {
        validResult = validateVariableStandardOnMove(prop, rule, movedVariable);
      } else if (validatorOnMove) {
        validResult = validatorOnMove(prop, rule, movedVariable);
      }
      if (!validResult.valid) {
        validResult.rule = rule;
        return validResult;
      }
    }

    return validResult;
    // });
  };

  let ifMoveToTarget = false; //记录当前移动是否已经成功
  let moveErrorCallback; //记录当前非法移动的回调函数

  const validateDraggable = (event) => {
    // const { newIndex, oldIndex } = { ...event }; // 获取目标在新数组的index和原数组的index
    const targetProp = event.to.id; // 停靠的目的地id（即targetprop）

    if (targetProp == "variableSourceRef") {
      return true;
    }
    // 非穿梭框prop不停靠
    if (!props.targetProps.includes(targetProp)) {
      return false;
    }

    let movedVariable = event.draggedContext.element; // 当前被移动的变量对象

    let res = validatePropOnMove(targetProp, movedVariable, (res) => {});

    // 如果非法则更新回调
    if (!res.valid) {
      if (res.rule?.moveErrorCallback) {
        moveErrorCallback = res.rule.moveErrorCallback;
      }
    } else {
      ifMoveToTarget = true;
      moveErrorCallback = null;
    }

    return res.valid;
  };

  provide("validateDraggable", validateDraggable);

  return {
    sourceVariableList,
    onEnd,
    validate,
    validateProp,
    validateMin,
    validateMax,
    validateVariableType,
    validateCustom,
    validatePropOnMove,
    validateMaxOnMove,
    validateVariableTypeOnMove,
    validateVariableStandardOnMove,
    validateDraggable,
    ifMoveToTarget,
    moveErrorCallback,
    generateSourceVariableList,
    // formData,
  };
}
