import { ref } from "vue";
import { httpPost } from "@/api/httpService.js";

export function useAnalysisCondition({
  libraryId,
  analysisId,
  targetProps = [],
  processAnalysisConditionFn,
  defaultAnalysisConfig = {},
}) {
  // 默认处理从后端获取到的数据的逻辑，支持重新定义
  processAnalysisConditionFn =
    processAnalysisConditionFn ||
    (({ res }) => {
      let originAnalysisConfig =
        JSON.parse(res.data.analysisConfig) || defaultAnalysisConfig || {};
      return generateData(originAnalysisConfig, targetProps);
    });
  const analysisConditionLoading = ref(false);
  const allVariableList = ref([]);
  const analysisConfig = ref({}); // 分析条件的配置

  const getAllVariableDataList = async () => {
    let params = {
      libId: libraryId,
      keyword: null,
    };

    const promise = new Promise((resolve, reject) => {
      httpPost(`/lib/variable/v1/list`, params).then((res) => {
        if (res.code == 0) {
          resolve(res);
        }
      });
    });
    return promise;
  };
  const getAnalysisDetail = async () => {
    let params = {
      id: analysisId,
    };
    const promise = new Promise((resolve, reject) => {
      httpPost(`/analysis/v1/getDetail`, params).then((res) => {
        if (res.code == 0) {
          //TODO
          resolve(res);
        }
      });
    });
    return promise;
  };
  const initAnalysisCondition = () => {
    analysisConditionLoading.value = true;
    const allVariablePromise = getAllVariableDataList();
    const analysisDetailPromise = getAnalysisDetail();
    Promise.all([allVariablePromise, analysisDetailPromise]).then((res) => {
      allVariableList.value = res[0].data;
      analysisConfig.value = processAnalysisConditionFn({
        res: res[1],
        targetProps,
      });
      analysisConditionLoading.value = false;
    });
  };
  initAnalysisCondition();

  // 根据变量id（单个colname字符串或多个colname组成的数组，从allvariable中获取对象列表
  const getVariablesByVariableId = (variableId) => {
    if (!Array.isArray(variableId)) {
      variableId = [variableId];
    }
    return allVariableList.value?.filter((variable) => {
      return variableId?.includes(variable.id);
    });
  };
  // const getVariablesByColname = (colname) => {
  //   if (!Array.isArray(colname)) {
  //     colname = [colname];
  //   }
  //   return allVariableList.value?.filter((variable) => {
  //     return colname?.includes(variable);
  //   });
  // };

  // 根据变量列表获取colname列表
  const getVariableIdsByVariables = (variables) => {
    return variables?.map((variable) => variable.id);
  };
  // const getColnamesByVariables = (variables) => {
  //   return variables?.map((variable) => variable.colname);
  // };

  // 根据targetProps，将对应的字段全都转换成colname数组，最终返回stringify的结果
  const generateAnalysisConfigOfParams = (
    _singleVariableProp = [],
    analysisConfigForm = analysisConfig.value,
    _targetProps = targetProps
  ) => {
    // let _targetProps = targetProps;
    let result = JSON.parse(JSON.stringify(analysisConfigForm));
    for (let key in result) {
      if (_targetProps.includes(key)) {
        if (_singleVariableProp.includes(key)) {
          result[key] = result[key]?.[0]?.id;
        } else {
          let variableList;
          variableList = result[key];
          result[key] = variableList.map((variable) => {
            if (typeof variable == "object") {
              return variable.id;
            } else if (typeof variable == "string") {
              return variable;
            }
          });
        }
      }
    }
    return result;
  };

  // 根据targetprops，将后端返回的字符串转换成组件所需数据
  const generateData = (analysisConfig, targetProps) => {
    targetProps.forEach((key) => {
      let variableIds = analysisConfig[key] || []; //取得对应的值，如果为空则默认为[]
      if (!Array.isArray(variableIds)) {
        // 如果接受的值不是数组，则转换成数组
        variableIds = [variableIds];
      }
      analysisConfig[key] = allVariableList.value.filter((variable) => {
        return variableIds.includes(variable.id);
      });
    });

    return analysisConfig;
  };

  // 清空表单
  const resetAnalysisConfig = () => {
    analysisConfig.value = JSON.parse(JSON.stringify(defaultAnalysisConfig));
  };

  // 变量更改后更新选择变量
  const refreshVariableDataList = () => {
    analysisConditionLoading.value = true;
    const allVariablePromise = getAllVariableDataList();
    Promise.all([allVariablePromise]).then((res) => {
      allVariableList.value = res[0].data;
      analysisConditionLoading.value = false;
    });
  };
  // 根据targetProp添加变量
  const addVariableToAnalysisConfigByTargetProp = (variable, targetProp) => {
    if (!variable || !targetProp || !targetProps.includes(targetProp)) {
      return;
    }
    analysisConfig.value[targetProp].push(variable);
  };
  return {
    allVariableList,
    analysisConditionLoading,
    getAllVariableDataList,
    analysisConfig,
    getAnalysisDetail,
    initAnalysisCondition,
    getVariablesByVariableId,
    // getVariablesByColname,
    getVariableIdsByVariables,
    // getColnamesByVariables,
    generateAnalysisConfigOfParams,
    generateData,
    resetAnalysisConfig,
    refreshVariableDataList,
    addVariableToAnalysisConfigByTargetProp,
  };
}
