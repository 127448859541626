import FrequencyAnalysisCondition from "@/views/analysis/components/FrequencyAnalysisCondition.vue";
import CrossAnalysisCondition from "@/views/analysis/components/CrossAnalysisCondition.vue";
import DescribeAnalysisCondition from "@/views/analysis/components/DescribeAnalysisCondition.vue";
import NormalityAnalysisCondition from "@/views/analysis/components/NormalityAnalysisCondition.vue";
import SubtotalAnalysisCondition from "@/views/analysis/components/SubtotalAnalysisCondition.vue";
import CorreationAnalysisCondition from "@/views/analysis/components/CorreationAnalysisCondition.vue";
import ReliabilityAnalysisCondition from "@/views/analysis/components/ReliabilityAnalysisCondition.vue";
import ValidityAnalysisCondition from "@/views/analysis/components/ValidityAnalysisCondition.vue";

import SingleTTestCondition from "@/views/analysis/components/SingleTTestCondition.vue";
import PairTTestCondition from "@/views/analysis/components/PairTTestCondition.vue";
import IndividualTTestCondition from "@/views/analysis/components/IndividualTTestCondition.vue";
import VarianceAnalysisCondition from "@/views/analysis/components/VarianceAnalysisCondition.vue";

import GrayRelationalAnalysisCondition from "@/views/analysis/components/GrayRelationalAnalysisCondition.vue";
import AprioriRelationalAnalysisCondition from "@/views/analysis/components/AprioriRelationalAnalysisCondition.vue";

import LinearRegressionAnalysisCondition from "@/views/analysis/components/LinearRegressionAnalysisCondition.vue";
import StepRegressionAnalysisCondition from "@/views/analysis/components/StepRegressionAnalysisCondition.vue";
import BinaryLogisticAnalysisCondition from "@/views/analysis/components/BinaryLogisticAnalysisCondition.vue";
import MultipleLogisticAnalysisCondition from "@/views/analysis/components/MultipleLogisticAnalysisCondition.vue";
import OrderedLogisticAnalysisCondition from "@/views/analysis/components/OrderedLogisticAnalysisCondition.vue";
import RidgeRegressionAnalysisCondition from "@/views/analysis/components/RidgeRegressionAnalysisCondition.vue";
import ElasticNetRegressionAnalysisCondition from "@/views/analysis/components/ElasticNetRegressionAnalysisCondition.vue";
import PoissonRegressionAnalysisCondition from "@/views/analysis/components/PoissonRegressionAnalysisCondition.vue";
import MultinomialRegressionAnalysisCondition from "@/views/analysis/components/MultinomialRegressionAnalysisCondition.vue";

import PrincipalComponentsAnalysisCondition from "@/views/analysis/components/PrincipalComponentsAnalysisCondition.vue";
import ExploratoryFactorAnalysisCondition from "@/views/analysis/components/ExploratoryFactorAnalysisCondition.vue";

import GrangerTestAnalysisCondition from "@/views/analysis/components/GrangerTestAnalysisCondition.vue";

// import HierarchicalClusterCondition from "@/views/analysis/components/HierarchicalClusterCondition.vue";
import KMeansClusterCondition from "@/views/analysis/components/KMeansClusterCondition.vue";
import DbscanClusterCondition from "@/views/analysis/components/DbscanClusterCondition.vue";
import KMedoidsClusterCondition from "@/views/analysis/components/KMedoidsClusterCondition.vue";
import DecisionTreeCondition from "@/views/analysis/components/DecisionTreeCondition.vue";

import ScatterDiagramCondition from "@/views/analysis/components/ScatterDiagramCondition.vue";
import NormalDistributionCondition from "@/views/analysis/components/NormalDistributionCondition.vue";
import BoxPlotCondition from "@/views/analysis/components/BoxPlotCondition.vue";
import PPQQPlotCondition from "@/views/analysis/components/PPQQPlotCondition.vue";

import { ShallowRef } from "vue";

export const ANALYSIS_TYPE = {
  /**
   * 频数分析
   */
  FREQUENCY_ANALYSIS: 101,
  /**
   * 描述性统计
   */
  DESCRIBE_ANALYSIS: 102,
  /**
   * 正态性检验
   */
  NORMALITY_ANALYSIS: 103,
  /**
   * 信度分析
   */
  RELIABILITY_ANALYSIS: 104,
  /**
   * 效度分析
   */
  VALIDITY_ANALYSIS: 105,
  /**
   * 分类汇总
   */
  SUBTOTAL_ANALYSIS: 106,

  /**
   * 相关分析
   */
  CORREATION_ANALYSIS: 201,
  /**
   * 线性回归分析
   */
  LINEAR_REGRESSION_ANALYSIS: 202,
  /**
   * 二元逻辑回归分析
   */
  BINARY_LOGISTIC_ANALYSIS: 203,
  /**
   * 灰色关联分析
   */
  GRAY_RELATIONAL_ANALYSIS: 204,
  /**
   * Apriori关联分析
   */
  APRIORI_RELATIONAL_ANALYSIS: 205,
  /**
   * 岭回归分析
   */
  RIDGE_REGRESSION_ANALYSIS: 206,
  /**
   * Lasso回归分析
   */
  LASSO_REGRESSION_ANALYSIS: 207,
  /**
   * 弹性网络回归分析
   */
  ELASTIC_NET_REGRESSION_ANALYSIS: 208,
  /**
   * 泊松回归分析
   */
  POISSON_REGRESSION_ANALYSIS: 209,
  /**
   * 多项式回归分析
   */
  MULTINOMIAL_REGRESSION_ANALYSIS: 210,
  /**
   * 逐步回归分析
   */
  STEP_REGRESSION_ANALYSIS: 211,
  /**
   * 分层回归分析
   */
  HIERARCHICAL_REGRESSION_ANALYSIS: 212,
  /**
   * 格兰杰因果检验
   */
  GRANGER_TEST_ANALYSIS: 213,
  /**
   * 多元逻辑回归
   */
  MULTIPLE_LOGISTIC_ANALYSIS: 214,
  /**
   * 有序逻辑回归
   */
  ORDERED_LOGISTIC_ANALYSIS: 215,

  /**
   * 交叉/卡方
   */
  CROSS_ANALYSIS: 301,
  /**
   * 单样本T检验
   */
  SINGLE_T_TEST: 302,
  /**
   * 配对样本T检验
   */
  PAIR_T_TEST: 303,
  /**
   * 独立样本T检验
   */
  INDIVIDUAL_T_TEST: 304,
  /**
   * 方差分析
   */
  VARIANCE_ANALYSIS: 305,

  /**
   * 主成分分析
   */
  PRINCIPAL_COMPONENTS_ANALYSIS: 401,
  /**
   * 探索性因子分析
   */
  EXPLORATORY_FACTORY_ANALYSIS: 402,
  /**
   * 验证性因子分析
   */
  CONFIRMATORY_FACTORY_ANALYSIS: 403,

  /**
   * 结构方程模型分析
   */
  STRUCTURAL_EQUATION_MODELING: 501,
  /**
   * 结构方程模型分析（暂未提供对应分析）
   */
  SEM_PLS: 502,

  /**
   * 决策树分析
   */
  DECISION_TREE: 601,

  /**
   * k-means分析
   */
  K_MEANS_CLUSTER: 701,
  /**
   * k-medoids分析
   */
  K_MEDOIDS_CLUSTER: 702,
  /**
   * 层次聚类分析
   */
  HIERARCHICAL_CLUSTER: 703,
  /**
   * DBSCAN分析
   */
  DBSCAN_CLUSTER: 704,

  /**
   * R端分析方法id最大值
   */
  R_ANALYSIS_METHOD_ID_MAX: 10000,

  /**
   * 散点图
   */
  SCATTER_DIAGRAM: 10101,
  /**
   * 正态图
   */
  NORMAL_DISTRIBUTION: 801,
  /**
   * 箱线图
   */
  BOX_PLOT: 802,
  /**
   * PPQQ图
   */
  PPQQ_PLOT: 803,
};
//TODO 需要完善description
export const ANALYSIS_TYPE_MAP = {
  [ANALYSIS_TYPE.FREQUENCY_ANALYSIS]: {
    label: "频数分析",
    description:
      "频数分析用于研究定类数据的分布情况，各类别的频数和百分比分别是多少。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.DESCRIBE_ANALYSIS]: {
    label: "描述性统计",
    description:
      "描述分析用于研究定量数据的整体情况，包括数据的集中趋势、离散程度、分布形态等分析。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.NORMALITY_ANALYSIS]: {
    label: "正态性检验",
    description:
      "正态性检验用于确定样本数据是否来自正态分布的总体。小样本(小于50)时建议使用S-W检验，大样本(大于50)时建议使用K-S检验。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.RELIABILITY_ANALYSIS]: {
    label: "信度分析",
    description:
      "信度分析主要用来考察问卷中量表所测结果的稳定性以及一致性，即用于检验问卷中量表样本是否可靠可信。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.VALIDITY_ANALYSIS]: {
    label: "效度分析",
    description:
      "效度用于测量题项(定量数据)设计是否合理,通过因子分析(探索性因子分析)方法进行验证。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.SUBTOTAL_ANALYSIS]: {
    label: "分类汇总",
    description:
      "分类汇总用于研究不同分类时的汇总情况（比如不同区域的销售额的差异情况）。",
    detailUrl: "", //查看详情链接
  },

  [ANALYSIS_TYPE.CORREATION_ANALYSIS]: {
    label: "相关分析",
    description:
      "相关分析是一种用来评价两组数据之间的关联关系强弱的统计方法。常用的相关性系数包括Pearson相关系数、Spearman相关系数、Kendall相关系数。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.LINEAR_REGRESSION_ANALYSIS]: {
    label: "线性回归分析",
    description:
      "线性回归是利用线性回归方程对一个或多个自变量和因变量之间关系进行建模的一种回归分析，它使用线性预测函数来建模，未知的模型参数则通过数据来估计。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.BINARY_LOGISTIC_ANALYSIS]: {
    label: "二元逻辑回归分析",
    description: "研究分类因变量Y与一些影响因素X之间关系的一种变量分析方法。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.MULTIPLE_LOGISTIC_ANALYSIS]: {
    label: "多元逻辑回归",
    description: "研究多分类因变量Y与一些影响因素X之间关系的一种变量分析方法。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.ORDERED_LOGISTIC_ANALYSIS]: {
    label: "有序逻辑回归",
    description:
      "研究有序分类因变量Y与一些影响因素X之间关系的一种变量分析方法。",
    detailUrl: "", //查看详情链接
  },

  [ANALYSIS_TYPE.GRAY_RELATIONAL_ANALYSIS]: {
    label: "灰色关联分析",
    description:
      "灰色关联分析法是根据因素之间发展趋势的相似或相异程度，作为衡量因素间关联程度的一种方法。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.APRIORI_RELATIONAL_ANALYSIS]: {
    label: "Apriori关联分析",
    description:
      "Apriori算法的核心思想是基于频繁项集理论的递归方法，采用逐层搜索的方法挖掘所有频繁项集，直至找到最高阶频繁项集，再对获得的频繁项集计算强关联规则。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.RIDGE_REGRESSION_ANALYSIS]: {
    label: "岭回归分析",
    description:
      "岭回归是一种专用于共线性数据的有偏估计的方法，放弃最小二乘法的无偏性，以损失部分信息、降低精度为代价获得回归系数更为符合实际、更可靠的回归方法。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.LASSO_REGRESSION_ANALYSIS]: {
    label: "Lasso回归分析",
    description:
      "Lasso方法是一种替代最小二乘法的压缩估计方法。Lasso的基本思想是建立一个L1正则化模型来压缩一些系数和设定一些系数为零，常用于共线性处理和变量选择。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.ELASTIC_NET_REGRESSION_ANALYSIS]: {
    label: "弹性网络回归分析",
    description:
      "弹性网络是一种使用L1、L2范数作为先验正则项训练的线性回归模型。这种组合允许学习到一个只有少量参数是非零稀疏的模型。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.POISSON_REGRESSION_ANALYSIS]: {
    label: "泊松回归分析",
    description:
      "泊松回归是用来为计数资料和列联表建模的一种回归分析。泊松回归假设因变量Y符合泊松分布，并假设它期望值的对数可由一组未知参数进行线性表达。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.MULTINOMIAL_REGRESSION_ANALYSIS]: {
    label: "多项式回归分析",
    description:
      "多项式回归是指研究一个因变量与自变量间多项式的回归分析方法。多项式回归模型是线性回归模型的一种，其回归函数关于回归系数是线性的。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.STEP_REGRESSION_ANALYSIS]: {
    label: "逐步回归分析",
    description:
      "逐步回归的思想是将变量逐个引入模型，每次引入都会进行检验并删除不显著的变量，直到没有新变量引入也没有旧变量删除，从而保证模型中每一个变量都有意义。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.HIERARCHICAL_REGRESSION_ANALYSIS]: {
    label: "分层回归分析",
    description:
      "分层回归是对若干个自变量X进行分群组分析，主要用于模型的比较，或者说对变量重要性进行判定（核心研究变量最后进入模型）。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.GRANGER_TEST_ANALYSIS]: {
    label: "格兰杰因果检验",
    description:
      "用于研究两组数据的因果关系，即检验一组时间序列是否会引起另一组时间序列的变化。如果说 A 是 B 的格兰杰原因，则说明 A 的变化是引起 B 变化的原因之一。",
    detailUrl: "", //查看详情链接
  },

  [ANALYSIS_TYPE.CROSS_ANALYSIS]: {
    label: "交叉/卡方",
    description:
      "交叉/卡方分析通常用于两个或两个以上分组（定类）变量之间的关系的探究，以交叉表的形式进行变量间关系的对比分析。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.SINGLE_T_TEST]: {
    label: "单样本T检验",
    description:
      "单样本T检验通常作为检验一组来自符合正态分布特征的总体且彼此独立的样本的均值的是否为某一数值的方法。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.PAIR_T_TEST]: {
    label: "配对样本T检验",
    description:
      "配对样本T检验用于检验同一统计量的两组样本均值之间的差异是否为零，这两组样本的数据必须来自符合正态分布特征的总体。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.INDIVIDUAL_T_TEST]: {
    label: "独立样本T检验",
    description:
      "独立样本T检验用于检验两组彼此独立的样本均值是否存在显著差异，这两组样本的数据必须来自符合正态分布特征的总体。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.VARIANCE_ANALYSIS]: {
    label: "单因素方差分析",
    description:
      "单因素方差分析用于分析单一控制变量（分组变量数=1）影响下的多组（通常组别≧3）样本的均值是否存在显著性差异。",
    detailUrl: "", //查看详情链接
  },

  [ANALYSIS_TYPE.PRINCIPAL_COMPONENTS_ANALYSIS]: {
    label: "主成分分析",
    description:
      "主成分将多个有一定相关性的指标进行线性组合，以最少的维度解释原数据中尽可能多的信息为目标进行降维，降维后的各变量间彼此线性无关。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.EXPLORATORY_FACTORY_ANALYSIS]: {
    label: "探索性因子分析",
    description:
      "探索性因子分析是基于降维的思想，在尽可能少损失原始数据信息的情况下，将错综复杂的众多变量聚合成少数几个独立的公共因子。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.CONFIRMATORY_FACTORY_ANALYSIS]: {
    label: "验证性因子分析",
    description:
      "验证性因子分析是用于测试一个因子与相对应的测度项之间的关系是否符合研究者所设计的理论关系的一种研究方法，可用于调查问卷的量表分析。",
    detailUrl: "", //查看详情链接
  },

  [ANALYSIS_TYPE.STRUCTURAL_EQUATION_MODELING]: {
    label: "结构方程模型分析",
    description:
      "结构方程模型是一种基于因子分析、线性回归方法，用于分析错综复杂变量之间路径关系的一种模型。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.SEM_PLS]: {
    label: "结构方程模型分析（暂未提供对应分析）",
  },

  [ANALYSIS_TYPE.DECISION_TREE]: {
    label: "决策树分析",
    description:
      "决策树是在已知各种情况发生概率的基础上，通过构成决策树来求取净现值的期望值大于等于零的概率，评价项目风险，判断其可行性的决策分析方法。",
    detailUrl: "", //查看详情链接
  },

  [ANALYSIS_TYPE.K_MEANS_CLUSTER]: {
    label: "k-means分析",
    description:
      "K-means聚类分析是一种基于中心的聚类算法，通过迭代，将样本分到K个类中，使得每个样本与其所属类的中心或均值的距离之和最小。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.K_MEDOIDS_CLUSTER]: {
    label: "k-medoids分析",
    description:
      "K-Medoids聚类法是基于“代表对象”的聚类方法，从现有的样本中选取出k个各自代表一个类的质心，并使得其他同属一类的样本点到这个点的距离之和为最小。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.HIERARCHICAL_CLUSTER]: {
    label: "层次聚类分析",
    description:
      "层次聚类也称分层聚类或系统聚类，它按照距离的远近使用逐层聚合的方式把所有元素逐步聚为一类，其对样本和变量的聚类都适用。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.DBSCAN_CLUSTER]: {
    label: "DBSCAN分析",
    description:
      "DBSCAN是一种经典的密度聚类方法，按照指定的搜索半径和最小密度把空间划分为高密度区域和低密度区域，并将高密度区域连接起来。",
    detailUrl: "", //查看详情链接
  },

  [ANALYSIS_TYPE.R_ANALYSIS_METHOD_ID_MAX]: {
    label: "R端分析方法id最大值",
  },

  [ANALYSIS_TYPE.SCATTER_DIAGRAM]: {
    label: "散点图",
    description:
      "散点图用于考察定量数据之间的关联关系，即查看X和Y之间的关系情况。散点图通常用于探索性研究，直观展示出数据间的关联关系情况。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.NORMAL_DISTRIBUTION]: {
    label: "正态图",
    description:
      "用于检验一组数据的分布是否满足正态分布。通过计算数据的均值、标准差，将理想的正态曲线与实际分布对比，可以得知数据在多大程度上接近于理想的正态分布。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.BOX_PLOT]: {
    label: "箱线图",
    description:
      "箱线图可以用来反映一组或多组连续型定量数据分布的中心位置和散布范围，它能够体现出数据的总体分布特征，并发现异常值点。",
    detailUrl: "", //查看详情链接
  },
  [ANALYSIS_TYPE.PPQQ_PLOT]: {
    label: "P-P/Q-Q图",
    description: "P-P图和Q-Q图常用于直观查看数据是否满足正态分布。",
    detailUrl: "", //查看详情链接
  },
};

// 分析条件组件映射
export const ANALYSIS_COMPONENT_MAP = {
  [ANALYSIS_TYPE.FREQUENCY_ANALYSIS]: FrequencyAnalysisCondition,
  [ANALYSIS_TYPE.CROSS_ANALYSIS]: CrossAnalysisCondition,
  [ANALYSIS_TYPE.DESCRIBE_ANALYSIS]: DescribeAnalysisCondition,
  [ANALYSIS_TYPE.SUBTOTAL_ANALYSIS]: SubtotalAnalysisCondition,
  [ANALYSIS_TYPE.NORMALITY_ANALYSIS]: NormalityAnalysisCondition,
  [ANALYSIS_TYPE.CORREATION_ANALYSIS]: CorreationAnalysisCondition,
  [ANALYSIS_TYPE.RELIABILITY_ANALYSIS]: ReliabilityAnalysisCondition,
  [ANALYSIS_TYPE.VALIDITY_ANALYSIS]: ValidityAnalysisCondition,

  [ANALYSIS_TYPE.SINGLE_T_TEST]: SingleTTestCondition,
  [ANALYSIS_TYPE.PAIR_T_TEST]: PairTTestCondition,
  [ANALYSIS_TYPE.INDIVIDUAL_T_TEST]: IndividualTTestCondition,
  [ANALYSIS_TYPE.VARIANCE_ANALYSIS]: VarianceAnalysisCondition,

  [ANALYSIS_TYPE.GRAY_RELATIONAL_ANALYSIS]: GrayRelationalAnalysisCondition,
  [ANALYSIS_TYPE.APRIORI_RELATIONAL_ANALYSIS]:
    AprioriRelationalAnalysisCondition,

  [ANALYSIS_TYPE.LINEAR_REGRESSION_ANALYSIS]: LinearRegressionAnalysisCondition,
  [ANALYSIS_TYPE.STEP_REGRESSION_ANALYSIS]: StepRegressionAnalysisCondition,
  [ANALYSIS_TYPE.BINARY_LOGISTIC_ANALYSIS]: BinaryLogisticAnalysisCondition,
  [ANALYSIS_TYPE.MULTIPLE_LOGISTIC_ANALYSIS]: MultipleLogisticAnalysisCondition,
  [ANALYSIS_TYPE.ORDERED_LOGISTIC_ANALYSIS]: OrderedLogisticAnalysisCondition,
  [ANALYSIS_TYPE.RIDGE_REGRESSION_ANALYSIS]: RidgeRegressionAnalysisCondition,
  [ANALYSIS_TYPE.MULTINOMIAL_REGRESSION_ANALYSIS]:
    MultinomialRegressionAnalysisCondition,

  [ANALYSIS_TYPE.ELASTIC_NET_REGRESSION_ANALYSIS]:
    ElasticNetRegressionAnalysisCondition,
  [ANALYSIS_TYPE.POISSON_REGRESSION_ANALYSIS]:
    PoissonRegressionAnalysisCondition,

  [ANALYSIS_TYPE.PRINCIPAL_COMPONENTS_ANALYSIS]:
    PrincipalComponentsAnalysisCondition,
  [ANALYSIS_TYPE.EXPLORATORY_FACTORY_ANALYSIS]:
    ExploratoryFactorAnalysisCondition,

  [ANALYSIS_TYPE.GRANGER_TEST_ANALYSIS]: GrangerTestAnalysisCondition,

  // [ANALYSIS_TYPE.HIERARCHICAL_CLUSTER]: HierarchicalClusterCondition,
  [ANALYSIS_TYPE.K_MEANS_CLUSTER]: KMeansClusterCondition,
  [ANALYSIS_TYPE.DBSCAN_CLUSTER]: DbscanClusterCondition,
  [ANALYSIS_TYPE.K_MEDOIDS_CLUSTER]: KMedoidsClusterCondition,
  [ANALYSIS_TYPE.DECISION_TREE]: DecisionTreeCondition,

  [ANALYSIS_TYPE.SCATTER_DIAGRAM]: ScatterDiagramCondition,
  [ANALYSIS_TYPE.NORMAL_DISTRIBUTION]: NormalDistributionCondition,
  [ANALYSIS_TYPE.BOX_PLOT]: BoxPlotCondition,
  [ANALYSIS_TYPE.PPQQ_PLOT]: PPQQPlotCondition,
};
export const ANALYSIS_SIDE_LIST = [
  {
    groupId: "1",
    icon: "数据描述-分析.png",
    title: "数据描述",
    children: [
      {
        name: "频数分析",
        algorithmId: ANALYSIS_TYPE.FREQUENCY_ANALYSIS,
      },
      { name: "交叉/卡方", algorithmId: ANALYSIS_TYPE.CROSS_ANALYSIS },
      {
        name: "描述性统计",
        algorithmId: ANALYSIS_TYPE.DESCRIBE_ANALYSIS,
      },
      //TODO 新增类型
      {
        name: "分类汇总",
        algorithmId: ANALYSIS_TYPE.SUBTOTAL_ANALYSIS,
      },
      {
        name: "正态性检验",
        algorithmId: ANALYSIS_TYPE.NORMALITY_ANALYSIS,
      },
      {
        name: "信度分析",
        algorithmId: ANALYSIS_TYPE.RELIABILITY_ANALYSIS,
      },
      { name: "效度分析", algorithmId: ANALYSIS_TYPE.VALIDITY_ANALYSIS },
    ],
  },
  {
    groupId: "2",
    icon: "差异研究-分析.png",
    title: "差异研究",
    style: "{font-size: 22px}",
    children: [
      {
        name: "单样本T检验",
        algorithmId: ANALYSIS_TYPE.SINGLE_T_TEST,
      },
      { name: "配对样本T检验", algorithmId: ANALYSIS_TYPE.PAIR_T_TEST },
      {
        name: "独立样本T检验",
        algorithmId: ANALYSIS_TYPE.INDIVIDUAL_T_TEST,
      },
      { name: "单因素方差分析", algorithmId: ANALYSIS_TYPE.VARIANCE_ANALYSIS },
      { name: "双因素", algorithmId: "", disabled: true },
      { name: "三因素", algorithmId: "", disabled: true },
      { name: "多因素", algorithmId: "", disabled: true },
      // TODO
      //   { name: "事后多重比较", algorithmId: "" },
      // TODO
      //   { name: "多因素方差分析", algorithmId: "" },
    ],
  },
  {
    groupId: "3",
    icon: "变量关系-分析.png",
    title: "变量关系",
    children: [
      {
        name: "相关分析",
        algorithmId: ANALYSIS_TYPE.CORREATION_ANALYSIS,
      },
      { name: "结构方程模型", algorithmId: "", disabled: true },
      {
        name: "灰色关联",
        algorithmId: ANALYSIS_TYPE.GRAY_RELATIONAL_ANALYSIS,
      },
      {
        name: "Apriori关联",
        algorithmId: ANALYSIS_TYPE.APRIORI_RELATIONAL_ANALYSIS,
      },
      {
        name: "格兰杰因果检验",
        algorithmId: ANALYSIS_TYPE.GRANGER_TEST_ANALYSIS,
      },
    ],
  },
  // 回归分析
  {
    groupId: "4",
    icon: "回归分析-分析.png",
    title: "回归分析",
    children: [
      {
        name: "线性回归",
        algorithmId: ANALYSIS_TYPE.LINEAR_REGRESSION_ANALYSIS,
      },
      {
        name: "逐步回归",
        algorithmId: ANALYSIS_TYPE.STEP_REGRESSION_ANALYSIS,
      },
      {
        name: "二元逻辑回归",
        algorithmId: ANALYSIS_TYPE.BINARY_LOGISTIC_ANALYSIS,
      },
      {
        name: "多元逻辑回归",
        algorithmId: ANALYSIS_TYPE.MULTIPLE_LOGISTIC_ANALYSIS,
      },
      // {
      //   name: "有序逻辑回归",
      //   algorithmId: ANALYSIS_TYPE.ORDERED_LOGISTIC_ANALYSIS,
      // },

      {
        name: "泊松回归",
        algorithmId: ANALYSIS_TYPE.POISSON_REGRESSION_ANALYSIS,
      },
      {
        name: "岭回归",
        algorithmId: ANALYSIS_TYPE.RIDGE_REGRESSION_ANALYSIS,
        disabled: true,
      },
      {
        name: "Lasso回归",
        algorithmId: ANALYSIS_TYPE.LASSO_REGRESSION_ANALYSIS,
        disabled: true,
      },
      {
        name: "弹性网络回归",
        algorithmId: ANALYSIS_TYPE.ELASTIC_NET_REGRESSION_ANALYSIS,
        disabled: true,
      },
      {
        name: "多项式回归",
        algorithmId: ANALYSIS_TYPE.MULTINOMIAL_REGRESSION_ANALYSIS,
        disabled: true,
      },
      {
        name: "分层回归",
        algorithmId: ANALYSIS_TYPE.HIERARCHICAL_REGRESSION_ANALYSIS,
        disabled: true,
      },

      //TODO
      //   {
      //     name: "稳健回归",
      //     algorithmId:"",
      //   },
      //TODO
      //   {
      //     name: "面板模型",
      //     algorithmId:"",
      //   },
      //TODO
      //   {
      //     name: "分位数回归",
      //     algorithmId:"",
      //   },
      //TODO
      //   {
      //     name: "两阶段回归",
      //     algorithmId:"",
      //   },
      //TODO
      //   {
      //     name: "GMM估计",
      //     algorithmId:"",
      //   },
      //TODO
      //   {
      //     name: "双重差分DID",
      //     algorithmId:"",
      //   },
      //TODO
      //   {
      //     name: "Tobit回归",
      //     algorithmId:"",
      //   },
    ],
  },
  // 因子分析
  {
    groupId: "5",
    icon: "因子分析.png",
    title: "因子分析",
    children: [
      {
        name: "主成分分析",
        algorithmId: ANALYSIS_TYPE.PRINCIPAL_COMPONENTS_ANALYSIS,
      },
      {
        name: "探索性因子分析",
        algorithmId: ANALYSIS_TYPE.EXPLORATORY_FACTORY_ANALYSIS,
        style: { fontSize: "14px" },
      },
      //   {
      //     name: "验证性因子分析",
      //     algorithmId: ANALYSIS_TYPE.CONFIRMATORY_FACTORY_ANALYSIS,
      //   },
    ],
  },
  // 时间序列
  // {
  //   groupId: "6",
  //   icon: "时间序列分析-分析.png",
  //   title: "时间序列",
  //   children: [
  //TODO
  // {
  //   name: "单位根检验",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "偏(自)相关分析",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "ARIMA模型",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "GARCH模型",
  //   algorithmId: "",
  // },
  // {
  //   name: "格兰杰因果检验",
  //   algorithmId: ANALYSIS_TYPE.GRANGER_TEST_ANALYSIS,
  // },
  //TODO
  // {
  //   name: "VAR模型",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "季节SARIMA",
  //   algorithmId: "",
  // },
  //   ],
  // },
  // 综合评价
  // {
  //   groupId: "7",
  //   icon: "综合评价-分析.png",
  //   title: "综合评价",
  //   children: [
  //TODO
  // {
  //   name: "AHP层次分析",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "熵值法",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "TOPSIS",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "熵权TOPSIS",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "模糊综合评价",
  //   algorithmId: "",
  // },
  //   ],
  // },
  // 进阶方法
  // {
  //   groupId: "8",
  //   icon: "进阶方法-分析.png",
  //   title: "进阶方法",
  //   children: [
  //TODO
  // {
  //   name: "判别分析",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "典型相关",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "灰色预测模型",
  //   algorithmId: "",
  // },
  //TODO
  // {
  //   name: "结构方程模型",
  //   algorithmId: ANALYSIS_TYPE.STRUCTURAL_EQUATION_MODELING,
  // },
  //TODO
  // {
  //   name: "倾向得分匹配",
  //   algorithmId: "",
  // },
  //   ],
  // },
  // 机器学习
  // {
  //   groupId: "9",
  //   icon: "机器学习-分析.png",
  //   title: "机器学习",
  //   children: [
  //   //TODO
  // { name: "层次聚类", algorithmId: ANALYSIS_TYPE.HIERARCHICAL_CLUSTER },
  //   //TODO
  // { name: "K-means聚类", algorithmId: ANALYSIS_TYPE.K_MEANS_CLUSTER },
  //   //TODO
  // { name: "DBCSAN", algorithmId: ANALYSIS_TYPE.DBSCAN_CLUSTER },
  //   //TODO
  // { name: "K-medoids分析", algorithmId: ANALYSIS_TYPE.K_MEDOIDS_CLUSTER },
  //   //TODO
  //   { name: "BP神经网络", algorithmId: ANALYSIS_TYPE. },
  //   //TODO
  // { name: "决策树", algorithmId: ANALYSIS_TYPE.DECISION_TREE },
  //   //TODO
  //   { name: "随机森林", algorithmId: ANALYSIS_TYPE. },
  //   //TODO
  //   { name: "K近邻(KNN)", algorithmId: ANALYSIS_TYPE. },
  //   //TODO
  //   { name: "支持向量机", algorithmId: ANALYSIS_TYPE. },
  //   //TODO
  //   { name: "朴素贝叶斯", algorithmId: ANALYSIS_TYPE. },
  //   ],
  // },
  // 可视化作图
  {
    groupId: "10",
    icon: "可视化作图-分析.png",
    title: "可视化作图",
    children: [
      { name: "散点图", algorithmId: ANALYSIS_TYPE.SCATTER_DIAGRAM },
      { name: "正态图", algorithmId: ANALYSIS_TYPE.NORMAL_DISTRIBUTION },
      { name: "箱线图", algorithmId: ANALYSIS_TYPE.BOX_PLOT },
      //TODO
      { name: "P-P/Q-Q图", algorithmId: ANALYSIS_TYPE.PPQQ_PLOT },
    ],
  },
];
