<!-- DBSCAN分析 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
    >
      <AnalysisConditionFormItem prop="variables">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variables"
            row="auto"
            @onEnd="onEnd"
            title="已选变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加',
                MEASURE_STANDARD.RATION,
                '变量(变量数≧1)'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          <div class="analysis-subtype-item-item">
            聚类个数：
            <!-- TODO:默认值及值的范围需确认 -->
            <el-input-number
              v-model="analysisSubType1"
              :min="2"
              :max="20"
              :step="1"
              controls-position="right"
            />
            <BaseTooltip>
              <template #content>
                <p style="max-width: 250px">
                  两个样本被看作邻居的最大距离，默认值是0.5。
                </p>
              </template>
              <i class="iconfont icon-wenhao-tishi info-message"></i>
            </BaseTooltip>
          </div>
          <div class="analysis-subtype-item-item">
            <span>密度阈值：</span>
            <el-input-number
              v-model="analysisSubType2"
              :min="2"
              :max="20"
              :step="1"
              controls-position="right"
            />
            <BaseTooltip>
              <template #content>
                <p style="max-width: 250px">
                  只要样本点的密度大于某个阈值，则将该样本添加到最近的簇中。
                </p>
              </template>
              <i class="iconfont icon-wenhao-tishi info-message"></i>
            </BaseTooltip>
          </div>
        </div>
        <div class="analysis-subtype-item">
          <el-switch v-model="ifComponent" />
          保存类别变量
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";
import BaseTooltip from "@/components/base/BaseTooltip.vue";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();
const emits = defineEmits(["showLoading"]);
const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const ifComponent = ref(true);

// TODO:默认值及值的范围需确认
const analysisSubType1 = ref("2");
const analysisSubType2 = ref("2");

const libraryId = inject("libraryId");
const targetProps = ref(["variables"]);

const rules = ref({
  variables: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【已选变量】变量个数不能少于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    variables: [],
  },
});

const analysisConditionFormRef = ref(null);
const showLoading = () => {
  emits("showLoading");
};

// TODO:接口参数名有待确认

const onClickStart = async () => {
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let params = {
        libId: libraryId,
        analysisId: props.tabInfo.analysisId,
        analysisConfig: JSON.stringify({
          ...generateAnalysisConfigOfParams(),
        }),
      };
      showLoading();

      let res = await httpPost("/analysis/v1/saveAndRun", params);
      if (res.code == 0) {
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
