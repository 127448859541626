<template>
  <div class="condition-header">
    <div class="title">{{ title }}</div>
    <div class="tip">
      <span class="description">
        {{ ANALYSIS_TYPE_MAP[analysisType].description }}
      </span>
    </div>
  </div>
  <div class="condition-wrapper" v-loading="loading">
    <div class="left-analysis-container">
      <VariableSourceTransfer
        ref="variableSourceRef"
        name="variableSourceRef"
        v-model="sourceVariableList"
        @onEnd="onEnd"
        @updateVarList="null"
        :draggableValidator="validateDraggable"
        @refreshVarList="refreshVarList"
      ></VariableSourceTransfer>
    </div>
    <div class="middle-container">
      <VariableTransferButton
        :active="ifTransferButtonActive"
        @click="handleClickTransferButton"
        @click-option="handleClickTransferButtonItem"
        :options="transferOptions"
      ></VariableTransferButton>
    </div>
    <div class="right-analysis-container">
      <!-- {{ analysisConfig }} -->
      <slot></slot>
      <slot name="button-group">
        <div class="right-button-group">
          <el-button @click="handleClickReset">重置</el-button>
          <el-button @click="handleClickStartAnalysis" type="primary">
            开始分析
          </el-button>
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch,
  provide,
  onMounted,
  computed,
  toRef,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { Debounce } from "../../../utils/utils";

import VariableSourceTransfer from "@/components/variableTransfer/VariableSourceTransfer.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";
import VariableTransferButton from "@/components/variableTransfer/VariableTransferButton.vue";

import {
  ANALYSIS_TYPE_MAP,
  ANALYSIS_TYPE,
} from "@/constant/analysis/analysis_type.js";
import { MEASURE_STANDARD } from "@/constant/variable_standard.js";
import { useVariableTransfer } from "../../../utils/useVariableTransfer";

const props = defineProps({
  // 头部标题
  title: {
    type: [String],
    default: () => {},
  },
  // 分析类型
  analysisType: {
    type: [String, Number],
    required: true,
  },
  //当前分析id
  analysisId: {
    type: [String, Number],
    required: true,
  },
  // 当前分析所属数据集id
  libraryId: {
    type: [String, Number],
    required: true,
  },
  // 分析条件配置
  analysisConfig: {
    type: Object,
    default: () => {},
  },
  // 所有变量列表
  allVariableList: {
    type: Array,
  },
  // 目标穿梭框对应的属性
  targetProps: {
    type: Array,
  },
  // 下方分析条件是否loading
  loading: {
    type: Boolean,
  },
  // 校验规则
  rules: {
    type: Object,
    default: () => {},
  },
  transferOptions: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits([
  "clickStart",
  "reset",
  "refreshVarList",
  "addVariableToTargetProp",
]);

//TODO
const {
  sourceVariableList,
  onEnd,
  validate,
  validateDraggable,
  validatePropOnMove,
} = useVariableTransfer(props);

const refreshVarList = () => {
  emits("refreshVarList");
};

const handleClickStartAnalysis = () => {
  emits("clickStart");
};
const handleClickReset = () => {
  emits("reset");
};
const moveSelectedVaraibleToTargetProp = (targetProp) => {
  sourceSelectedList.value.forEach((sourceCol) => {
    let movedVariable = sourceVariableList.value.find((item) => {
      return sourceCol == item.id;
    });
    if (movedVariable) {
      let validRes = validatePropOnMove(targetProp, movedVariable);
      if (validRes.valid) {
        emits("addVariableToTargetProp", movedVariable, targetProp);
        // props.analysisConfig[targetProp].push(movedVariable);
      } else {
        validRes.rule.moveErrorCallback();
      }
    }
  });
};
const handleClickTransferButton = () => {
  if (props.targetProps.length == 1) {
    let targetProp = props.targetProps[0];
    moveSelectedVaraibleToTargetProp(targetProp);
    clearSourceSelectedList();
    // sourceSelectedList.value = [];
  }
};
const handleClickTransferButtonItem = (targetProp) => {
  moveSelectedVaraibleToTargetProp(targetProp);
  clearSourceSelectedList();
};

const variableSourceRef = ref(null);
let sourceSelectedList = [];
watch(
  () => variableSourceRef?.value?.selectedList,
  (newVal) => {
    if (newVal && newVal.length > 0) {
      // ifTransferButtonActive.value = true;
    }
  },
  {
    deep: true,
  }
);
const ifTransferButtonActive = computed(() => {
  return variableSourceRef?.value?.selectedList.length > 0;
});
// const sourceSelectedList = toRef(variableSourceRef.value, "selectedList");

const clearSourceSelectedList = () => {
  variableSourceRef.value.clearSelectedList();
};

onMounted(() => {
  sourceSelectedList = toRef(variableSourceRef.value, "selectedList");

  // sourceSelectedList = variableSourceRef.value.selectedList;
});
defineExpose({ onEnd, validateDraggable, validate });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition.scss";
</style>
