<template>
  <div
    class="transfer-wrapper"
    :style="{
      height: height,
      'flex-grow': 1,
      overflow: row == 'auto' ? 'auto' : '',
    }"
  >
    <div v-if="title" class="transfer-title">
      {{ title }}
    </div>
    <div class="transfer-box">
      <draggable
        :list="variableList"
        ghost-class="ghost"
        chosen-class="chosenClass"
        animation="0"
        @start="onStart"
        :move="onMove"
        @end="onEnd"
        @unchoose="onUnchoose"
        item-key="id"
        group="variableGroup"
        style="height: 100%"
        :id="name"
        :sort="false"
      >
        <template #item="{ element }">
          <div class="variable-item">
            <!-- {{ element }} -->
            <VariableTransferItem
              :data="element"
              :isSelected="element.isSelected"
              :active="isActive(element)"
              :removable="false"
              @deleteVar="handleDeleteVar"
              @updateVar="handleUpdateVar"
              @click="(event) => handleClickItem(element, event)"
            ></VariableTransferItem>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  watch,
  defineExpose,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import VariableTransferItem from "./VariableTransferItem.vue";
import draggable from "vuedraggable";

const props = defineProps({
  title: {
    type: String,
    default: () => "选择变量",
  },
  // 控制行数
  row: {
    type: [String, Number],
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  draggableValidator: {
    default: () => null,
  },
  // 用于定位onend移入了谁
  name: {
    type: String,
  },
});

const emits = defineEmits([
  "update:modelValue",
  "onEnd",
  "onUnchoose",
  "warning",
  "refreshVarList",
]);

const variableList = ref([]);
const selectedList = ref([]); // 记录当前选中的变量的id

// 监听modelValue 并修改varaibleList的值
watch(
  () => props.modelValue,
  (newVal) => {
    if (Array.isArray(newVal)) {
      variableList.value = newVal;
      selectedList.value = selectedList.value.filter((variableId) => {
        return (
          newVal.findIndex((variable) => {
            return variable.id == variableId;
          }) > -1
        );
      });
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

const handleDeleteVar = () => {
  emits("refreshVarList");
};
const clearSelectedList = () => {
  selectedList.value = [];
};
const isActive = (variable) => {
  return (
    selectedList.value.findIndex((item) => {
      return item == variable.id;
    }) > -1
  );
};
const lastActiveVariable = ref(null); // 记录最后激活的变量
const lastActiveIndex = computed(() => {
  let index = variableList.value.findIndex((variable) => {
    return variable?.id == lastActiveVariable.value?.id;
  });
  return index;
});
const handleClickItem = (item, event) => {
  let index = selectedList.value.findIndex((id) => {
    return id == item.id;
  });

  // shift+点击
  if (event.shiftKey) {
    if (lastActiveIndex.value > -1) {
      // 如果存在最后激活的index
      let variableIndex = variableList.value.findIndex((variable) => {
        return variable.id == item.id;
      });
      clearSelectedList();
      selectedList.value = variableList.value
        .slice(
          Math.min(variableIndex, lastActiveIndex.value),
          Math.max(variableIndex, lastActiveIndex.value) + 1
        )
        ?.map((item) => {
          return item.id;
        });
    } else {
      selectedList.value.push(item.id);
      lastActiveVariable.value = item;
    }
  } else {
    if (selectedList.value.length > 0) {
      // 已经存在被选中的变量时
      if (!event.ctrlKey) {
        // 没有ctrl单击时，直接单选
        clearSelectedList();
        selectedList.value.push(item.id);
        lastActiveVariable.value = item;
      } else {
        // 有ctrl时，如果已被勾选，则取消勾选
        if (index > -1) {
          selectedList.value.splice(index, 1);
        } else {
          selectedList.value.push(item.id);
        }
      }
    } else {
      if (index > -1) {
        selectedList.value.splice(index, 1);
      } else {
        lastActiveVariable.value = item;
        if (!event.ctrlKey) {
          clearSelectedList();
        }
        selectedList.value.push(item.id);
      }
    }
  }
};

const handleUpdateVar = () => {
  emits("refreshVarList");
};

const onStart = () => {};
const onEnd = (event) => {
  // emits("update:modelValue", variableList.value);
  emits("onEnd", event);
};
const onUnchoose = (event) => {
  // emits("update:modelValue", variableList.value);
  emits("onUnchoose", event);
};
const onMove = (event) => {
  let res = props.draggableValidator(event);
  return res;
  // if (!res.valid) {
  //   if(res.rule.moveErrorCallback)
  //   return false;
  // }
  // return true;
};

const titleHeight = 40; //标题高度
const padding = 5; //内部padding
const rowHeight = 30; // 行高
// 根据row属性计算容器高度
const height = computed(() => {
  return Number(props.row)
    ? `${(rowHeight + padding) * props.row + titleHeight + padding + 1}px`
    : "100%";
});
defineExpose({ selectedList, clearSelectedList });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/variable-transfer.scss";
</style>
