<template>
  <div>
    <el-dropdown @command="handleCommand" v-if="options.length > 0">
      {{ direction }}
      <el-button
        :icon="direction == 'vertical' ? Sort : Switch"
        circle
        :type="buttonParams.type"
        :plain="buttonParams.plain"
        :disabled="buttonParams.plain"
        @click="handleClickButton"
      ></el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="option in options"
            :key="option.prop"
            :command="option.prop"
          >
            {{ option.label }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-button
      :icon="direction == 'vertical' ? Sort : Switch"
      circle
      :type="buttonParams.type"
      :plain="buttonParams.plain"
      :disabled="buttonParams.plain"
      @click="handleClickButton"
      v-else
    ></el-button>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, watch } from "vue";
import { httpPost } from "@/api/httpService.js";
import { Switch, Sort } from "@element-plus/icons-vue";

const props = defineProps({
  // 是否可用
  active: {
    type: Boolean,
    default: () => false,
  },
  // 选项列项
  options: {
    type: Array,
    default: () => [],
  },
  direction: {
    type: String,
    default: () => "",
  },
});
const emits = defineEmits(["click", "clickOption"]);

const buttonParams = ref({
  type: "",
  plain: true,
  disabled: true,
});

watch(
  () => props.active,
  (newVal, oldVal) => {
    if (newVal) {
      buttonParams.value.type = "primary";
      buttonParams.value.plain = false;
      buttonParams.value.disabled = false;
    } else {
      buttonParams.value.type = "";
      buttonParams.value.plain = true;
      buttonParams.value.disabled = true;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

const handleClickButton = () => {
  emits("click");
};

const handleCommand = (command) => {
  emits("clickOption", command);
};
</script>

<style lang="scss" scoped></style>
