const ANALYSIS_STATE = {
  PENDING: 1,
  SUCCESS: 2,
  ERROR: 3,
  CREATED: 4,
};
const ANALYSIS_STATE_MAP = {
  [ANALYSIS_STATE.PENDING]: { label: "进行中", type: "warning" },
  [ANALYSIS_STATE.SUCCESS]: { label: "已完成", type: "success" },
  [ANALYSIS_STATE.ERROR]: { label: "异常", type: "danger" },
  [ANALYSIS_STATE.CREATED]: { label: "已创建", type: "info" },
};

export { ANALYSIS_STATE, ANALYSIS_STATE_MAP };
