<!-- 二元逻辑回归分析 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
      :transfer-options="transferOptions"
    >
      <AnalysisConditionFormItem prop="variable_y">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <!-- {{ draggableValidator }} -->
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variable_y"
            row="1"
            @onEnd="onEnd"
            title="因变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加因变量Y',
                MEASURE_STANDARD.CATEGORIZATION,
                '（变量数=1，变量值的种类=2)'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <AnalysisConditionFormItem prop="variable_x_list">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variable_x_list"
            row="auto"
            @onEnd="onEnd"
            title="自变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加自变量X',
                MEASURE_STANDARD.RATION,
                '（变量数≧1）'
              )
            "
            tipContent="参与回归的自变量大部分是定量变量，对于加入回归模型中的定类变量，系统会自动进行虚拟变量设置，进而进行回归分析"
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          <span>
            参照项
            <el-select
              v-model="analysisConfig.reference_item"
              placeholder="请选择参照项"
              style="width: 200px; margin-left: 12px"
            >
              <el-option
                v-for="valueOption in variableValueOptions"
                :value="valueOption.value"
                :label="valueOption.label"
                :key="valueOption.value"
              ></el-option>
            </el-select>
          </span>
        </div>
        <div class="analysis-subtype-item">
          <div class="analysis-subtype-item-item">
            <el-switch v-model="analysisConfig.save_value" />
            保存残差和预测值
          </div>
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
  computed,
  watch,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";
import { VARIABLE_TYPE, VARIABLE_MAP } from "@/constant/variable_type.js";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();

const emits = defineEmits(["showLoading"]);
const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

// const ifNormality = ref(true);

const libraryId = inject("libraryId");
const targetProps = ref(["variable_x_list", "variable_y"]);
const transferOptions = ref([
  { prop: "variable_y", label: "因变量" },
  { prop: "variable_x_list", label: "自变量" },
]);
const rules = ref({
  variable_y: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【因变量】变量个数不能少于1");
      },
    },
    {
      max: 1,
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning("【因变量】变量个数不能大于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.CATEGORIZATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.CATEGORIZATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
  variable_x_list: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【自变量】变量个数不能少于1");
      },
    },
    {
      variableStandard: [
        // MEASURE_STANDARD.CATEGORIZATION,
        MEASURE_STANDARD.RATION,
      ],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    variable_x_list: [],
    variable_y: [],
    save_value: true,
    reference_item: null,
  },
});
const variableValueList = ref([]);
const getVariableValueList = async (variableId) => {
  let params = {
    libraryId: libraryId,
    variableId: variableId,
    ifGenerateNewValue: true,
  };
  let res = await httpPost("/lib/data/v1/getVariableValueList", params);
  if (res.code == 0) {
    variableValueList.value = res.data;
  }
  return res;
};
watch(
  () => analysisConfig.value.variable_y,
  (newVal) => {
    let variableId = newVal?.[0]?.id;
    if (variableId) {
      getVariableValueList(variableId);
    } else {
      analysisConfig.value.reference_item = null;
      variableValueList.value = [];
    }
  },
  {
    deep: true,
  }
);
const getOldValueString = (item, prefix = "") => {
  let selectedVariable = analysisConfig?.value.variable_y?.[0] || {};

  if (selectedVariable?.type == VARIABLE_TYPE.STRING) {
    return (
      prefix +
      (item.newValue ? `${item.newValue}` : "") +
      `（${item.oldValue}）`
    );
  } else {
    return (
      `${prefix}${item.oldValue}` +
      (item.newValue ? `（${item.newValue}）` : "")
    );
  }
};
const variableValueOptions = computed(() => {
  let selectedVariable = analysisConfig?.value.variable_y?.[0] || {};
  if (!selectedVariable) {
    return [];
  }
  return variableValueList.value?.map((item) => {
    if (selectedVariable?.type == VARIABLE_TYPE.STRING) {
      return {
        value: item.oldValue,
        label: `${selectedVariable.colname}` + getOldValueString(item, "_"),
      };
    } else {
      return {
        value: item.oldValue,
        label: `${selectedVariable.colname}` + getOldValueString(item, "_"),
      };
    }
  });
});

const analysisConditionFormRef = ref(null);

const onClickStartAnalysis = async (params) => {
  let res = await httpPost("/analysis/v1/checkBinaryLogistic", params);
  if (res.code == 0) {
    if (!res.data) {
      proxy.message.warning("因变量取值种类只能为2种");
    }
    return res.data;
  }
};

const showLoading = () => {
  emits("showLoading");
};

const onClickStart = async () => {
  // TODO:showloading
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      if (!analysisConfig.value.reference_item) {
        proxy.message.warning("请选择参照项");
        return;
      }
      let params = {
        libId: libraryId,
        analysisId: props.tabInfo.analysisId,
        analysisConfig: JSON.stringify({
          ...generateAnalysisConfigOfParams(["variable_y"]),
          // save_value: ifNormality.value,
        }),
      };
      let check = await onClickStartAnalysis(params);
      if (check) {
        showLoading();
        let res = await httpPost("/analysis/v1/saveAndRun", params);
        if (res.code == 0) {
        }
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
