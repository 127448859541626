<!-- 探索性因子分析 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
    >
      <AnalysisConditionFormItem prop="variables">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variables"
            row="auto"
            @onEnd="onEnd"
            title="已选变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加',
                MEASURE_STANDARD.RATION,
                '变量(变量数≧2)'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          <div class="analysis-subtype-item-item">
            因子个数：
            <el-select
              v-model="analysisSubType1"
              placeholder="请选择"
              style="width: calc(100% - 80px)"
            >
              <el-option
                v-for="item in analysisSubTypeOptions1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="analysis-subtype-item-item">
            <span>旋转方法：</span>
            <el-select
              v-model="analysisSubType2"
              placeholder="请选择"
              style="width: calc(100% - 80px)"
            >
              <el-option
                v-for="item in analysisSubTypeOptions2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="analysis-subtype-item">
          <div class="analysis-subtype-item-item">
            <el-switch v-model="ifComponent" />
            保存因子得分
          </div>
          <div class="analysis-subtype-item-item">
            <el-switch v-model="ifComprehensive" />
            保存综合得分
          </div>
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();
const emits = defineEmits(["showLoading"]);
const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const ifComponent = ref(true);
const ifComprehensive = ref(true);

const analysisSubType1 = ref("0");
const analysisSubTypeOptions1 = ref([
  {
    value: "0",
    label: "自动设置因子维度",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
]);

const analysisSubType2 = ref("varimax");
const analysisSubTypeOptions2 = ref([
  {
    value: "none",
    label: "不旋转",
  },
  {
    value: "varimax",
    label: "最大方差法",
  },
  {
    value: "quartimax",
    label: "最大四次方值法",
  },
  {
    value: "equamax",
    label: "最大平衡值法",
  },
  // {
  //   value: "bentlerT",
  //   label: "bentlerT",
  // },
  // {
  //   value: "varimin",
  //   label: "varimin",
  // },
  // {
  //   value: "geominT",
  //   label: "geominT",
  // },
  // {
  //   value: "bifactor",
  //   label: "bifactor",
  // },
]);

const libraryId = inject("libraryId");
const targetProps = ref(["variables"]);

const rules = ref({
  variables: [
    {
      min: 2,
      validateErrorCallback: () => {
        proxy.message.warning("【已选变量】变量个数不能少于2");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    variables: [],
  },
});

const analysisConditionFormRef = ref(null);
const showLoading = () => {
  emits("showLoading");
};
const onClickStart = async () => {
  // TODO:showloading
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let params = {
        libId: libraryId,
        analysisId: props.tabInfo.analysisId,
        analysisConfig: JSON.stringify({
          ...generateAnalysisConfigOfParams(),
          factor_num:
            analysisSubType1.value > 0 ? analysisSubType1.value : null,
          method: analysisSubType2.value,
          left_open_flag: ifComponent.value,
          right_open_flag: ifComprehensive.value,
        }),
      };
      showLoading();

      let res = await httpPost("/analysis/v1/saveAndRun", params);
      if (res.code == 0) {
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
