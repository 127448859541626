<!-- 用于有icon的可修改的文本框的组件 -->
<template>
  <div class="text-edit-icon">
    <div v-if="!ifEdit" class="library-name list-item" @click="handleClickText">
      <i
        class="iconfont"
        :class="[icon, icon !== '' ? 'list-item-icon' : '']"
        :style="iconStyle"
        @click="handleClickText"
      ></i>
      <span :style="{ color: fontColor }">{{ text }}&nbsp;</span>

      <i
        v-if="editable"
        class="iconfont icon-bianji edit-icon"
        @click.stop="showEditor()"
      ></i>
    </div>
    <div v-else class="rename-box">
      <div class="input-box">
        <el-input
          ref="renameInputRef"
          size="small"
          v-model="newName"
          @keyup.enter="handleClickSave()"
        ></el-input>
      </div>
      <el-icon
        v-show="!ifShowLoading"
        class="icon-check"
        @click="handleClickSave()"
      >
        <Check />
      </el-icon>
      <el-icon
        v-show="!ifShowLoading"
        class="icon-close"
        @click="handleClickCancelRename()"
      >
        <Close />
      </el-icon>
      <el-icon class="icon-loading" v-show="ifShowLoading"><Loading /></el-icon>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch,
  nextTick,
  onMounted,
} from "vue";

const emits = defineEmits([
  "update:text",
  "update:loading",
  "save",
  "update:edit",
  "cancel",
  "go",
]);

const props = defineProps({
  text: {
    type: String,
    default: () => "",
  }, //传入的文本
  icon: {
    type: String,
    default: () => "",
  }, //所带有图标的iconflass
  editable: {
    type: Boolean,
    default: () => true,
  }, //是否可编辑
  edit: {
    type: Boolean,
    default: () => false,
  }, //是否编辑状态
  ifBind: {
    type: Boolean,
    default: () => false,
  }, //数据是否双向绑定,数据需要有v-model:text
  loading: {
    type: Boolean,
    default: () => false,
  }, //控制是否显示loading
  iconStyle: {
    type: Object,
    default: () => {},
  },
  fontColor: {
    type: String,
    default: () => "",
  },
});
const renameInputRef = ref(null);
onMounted(() => {
  renameInputRef.value = document.querySelector("renameInputRef");
});
const focusInput = () => {
  renameInputRef.value.focus();
};

let ifEdit = ref(false);
watch(
  () => props.edit,
  (newVal) => {
    ifEdit.value = newVal;
    nextTick(() => {
      hideLoading();
    });
    if (ifEdit.value) {
      nextTick(() => {
        hideLoading();
        showEditor();
        focusInput();
      });
    }
  },
  {
    immediate: true,
  }
);

const ifShowLoading = ref(false);
watch(
  () => props.loading,
  (newVal) => {
    ifShowLoading.value = newVal;
    // nextTick(() => {
    //   hideLoading();
    // });
    // if (ifEdit.value) {
    //   nextTick(() => {
    //     hideLoading();
    //     showEditor();
    //   });
    // }
  },
  {
    immediate: true,
  }
);
const showLoading = () => {
  ifShowLoading.value = true;
  emits("update:loading", ifShowLoading.value);
};
const hideLoading = () => {
  ifShowLoading.value = false;
  emits("update:loading", ifShowLoading.value);
};
const newName = ref("");

const showEditor = () => {
  ifEdit.value = true;
  emits("update:edit", ifEdit.value);

  newName.value = props.text;
};
const handleClickSave = () => {
  showLoading();
  // ifEdit.value = false;
  // emits("update:edit", ifEdit.value);
  if (props.ifBind) {
    emits("update:text", newName.value);
  }
  emits("save", newName.value);
};
const handleClickCancelRename = () => {
  ifEdit.value = false;
  emits("update:edit", ifEdit.value);
  emits("cancel");
};
const handleClickText = () => {
  emits("go");
};
</script>

<style lang="scss" scoped>
// @import "@/assets/styles/components/list-table";
.list-item {
  // 编辑图标的显示
  .edit-icon {
    margin-left: 8px;
    display: none;
  }

  &:hover {
    .edit-icon {
      display: inline-block;
    }
  }
  .list-item-icon {
    margin-right: 8px;
    font-size: 20px;
    position: relative;
    top: 2px;
  }
}
.rename-box {
  width: 100%;

  .input-box {
    width: 50%;
    display: inline-block;
  }

  .icon {
    margin-left: 8px;
    font-size: 18px;
    vertical-align: middle;
    @include pointer();
  }

  .icon-check {
    @extend .icon;
    color: $success-color;
  }

  .icon-close {
    @extend .icon;
    color: $primary-color;
  }
  .icon-loading {
    @extend .icon;
  }
}
.text-edit-icon {
  @include pointer();
  @include no-selectable();

  .icon-fill-folder {
    // color: $folder-icon-color;
  }
  .icon-fenxi {
    color: $theme-color;
  }
}
</style>
