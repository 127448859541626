<!-- 配对样本T检验 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
      :transfer-options="transferOptions"
    >
      <AnalysisConditionFormItem prop="variables1">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <!-- {{ draggableValidator }} -->
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variables1"
            row="auto"
            @onEnd="onEnd"
            title="配对变量1"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加',
                MEASURE_STANDARD.RATION,
                '变量1（变量数≧1)'
              )
            "
            tipContent="配对样本T检验的输入变量必须是配对的，如果有多组配对变量，可以同时输入多对"
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <AnalysisConditionFormItem prop="variables2">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variables2"
            row="auto"
            @onEnd="onEnd"
            title="配对变量2"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加',
                MEASURE_STANDARD.RATION,
                '变量2（变量数≧1)'
              )
            "
            tipContent="配对样本T检验的输入变量必须是配对的，如果有多组配对变量，可以同时输入多对"
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          <el-switch v-model="analysisConfig.open_flag" />
          正态性检验
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();

const emits = defineEmits(["showLoading"]);
const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

// const ifNormality = ref(true);

const libraryId = inject("libraryId");
const targetProps = ref(["variables2", "variables1"]);

const rules = ref({
  variables1: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【配对变量1】变量个数不能少于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
  variables2: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【配对变量2】变量个数不能少于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    variables1: [],
    variables2: [],
    open_flag: true,
  },
});

const transferOptions = ref([
  { prop: "variables1", label: "配对变量1" },
  { prop: "variables2", label: "配对变量2" },
]);

const analysisConditionFormRef = ref(null);

// const onEnd = ref(null);
// onMounted(() => {
//   onEnd.value = analysisConditionFormRef.value.onEnd;
// });

const showLoading = () => {
  emits("showLoading");
};
const onClickStartAnalysis = async (params) => {
  // TODO: 配对变量的样本量需要相等的判断还没加
  if (
    analysisConfig.value.variables1.length == 0 ||
    analysisConfig.value.variables2.length == 0
  ) {
    proxy.message.warning("配对变量个数不能少于1");
  } else if (
    analysisConfig.value.variables1.length !=
    analysisConfig.value.variables2.length
  ) {
    proxy.message.warning("配对变量的变量个数必须相同");
  } else {
    let res = await httpPost("/analysis/v1/checkPairTTest", params);
    if (res.code == 0) {
      if (!res.data) {
        proxy.message.warning("配对变量的样本量需要相等");
      }
      return res.data;
    }
  }
};

const onClickStart = async () => {
  // TODO:showloading
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let params = {
        libId: libraryId,
        analysisId: props.tabInfo.analysisId,
        analysisConfig: JSON.stringify({
          ...generateAnalysisConfigOfParams(),
          // open_flag: ifNormality.value,
        }),
      };
      let check = await onClickStartAnalysis(params);
      if (check) {
        showLoading();

        let res = await httpPost("/analysis/v1/saveAndRun", params);
        if (res.code == 0) {
        }
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
