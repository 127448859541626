<template>
  <div class="search-option-bar">
    <span class="search-bar">
      <el-input
        v-model="searchAnalysis"
        placeholder="请输入分析名称"
        :prefix-icon="Search"
        @input="searchAnalysisList"
        clearable
      />
    </span>
    <span class="option-bar">
      <span>
        <el-cascader
          v-model="analysisType"
          :options="analysisTypeOptions"
          :props="analysisTypeProps"
          :show-all-levels="false"
          @change="searchAnalysisList"
          clearable
        />
      </span>
      <span>
        <el-button plain @click="handleBatchDelete">批量删除</el-button>
      </span>
    </span>
  </div>
  <div class="analysis-list">
    <div class="list-table">
      <div class="table-wrapper">
        <el-table
          :data="analysisDataList"
          style="width: 100%"
          v-loading="tableLoading"
          header-cell-class-name="list-table-header"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50px" />
          <el-table-column prop="name" label="分析名称">
            <template #default="scope">
              <TextEditor
                :text="scope.row['name']"
                :editable="true"
                v-model:edit="scope.row.ifEdit"
                v-model:loading="scope.row.ifShowLoading"
                @save="(text) => handleClickSaveListItemName(text, scope.row)"
                @go="handleClickName(scope.row)"
              ></TextEditor>
            </template>
          </el-table-column>
          <el-table-column prop="algorithmId" label="分析方法">
            <template #default="scope">
              <div v-if="scope.row.algorithmId">
                {{ ANALYSIS_TYPE_MAP[scope.row.algorithmId]?.label }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="libName" label="数据范围"></el-table-column>
          <el-table-column prop="createTime" label="更新时间"></el-table-column>
          <el-table-column
            prop="createUserName"
            label="创建人"
          ></el-table-column>
          <el-table-column prop="state" label="状态">
            <template #default="scope">
              <div v-if="scope.row.state">
                <el-tag :type="ANALYSIS_STATE_MAP[scope.row.state].type">
                  {{ ANALYSIS_STATE_MAP[scope.row.state].label }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template #default="scope">
              <div class="list-item-btn">
                <el-button
                  link
                  type="primary"
                  @click="handleClickCondition(scope.row)"
                >
                  条件
                </el-button>
                <el-button
                  link
                  type="primary"
                  @click="handleClickResult(scope.row)"
                >
                  结果
                </el-button>
                <el-button link type="primary">
                  <el-dropdown
                    class="more-operation"
                    trigger="click"
                    @command="
                      (command) => handleSingleAnalysis(scope.row, command)
                    "
                  >
                    <el-button link type="primary">
                      更多
                      <el-icon class="el-icon--right"><arrow-down /></el-icon>
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="rename">
                          重命名
                        </el-dropdown-item>
                        <el-dropdown-item command="delete">
                          删除
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            v-model:current-page="pageNum"
            v-model:page-size="pageSize"
            small
            background
            layout="total, prev, pager, next, sizes"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="goToPage"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  getCurrentInstance,
  inject,
  computed,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { usePagination } from "@/utils/usePagination";
import { Debounce } from "@/utils/utils.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { Search, ArrowDown } from "@element-plus/icons-vue";
import { ERROR_CODE_NAME_DUPLICATE } from "@/constant/error_code.js";

import TextEditor from "@/components/base/TextEditor.vue";
import {
  ANALYSIS_TYPE_MAP,
  ANALYSIS_SIDE_LIST,
} from "@/constant/analysis/analysis_type.js";
import { ANALYSIS_STATE_MAP } from "@/constant/analysis/analysis_state.js";

const libraryId = inject("libraryId");

const { proxy } = getCurrentInstance();
const emits = defineEmits([
  "analysisItemCondition",
  "analysisItemResult",
  "analysisInfo",
  "refreshTabs",
]);

const handleClickCondition = (row) => {
  let analysisInfo = {
    algorithmId: row.algorithmId || null,
    analysisId: row.id || null,
    name: row.name || null,
  };
  emits("analysisItemCondition", analysisInfo);
};
const handleClickResult = (row) => {
  let analysisInfo = {
    algorithmId: row.algorithmId || null,
    analysisId: row.id || null,
    name: row.name || null,
  };
  emits("analysisItemResult", analysisInfo);
};

const searchAnalysis = ref("");
const analysisType = ref("");
const analysisTypeOptions = ref([]);
analysisTypeOptions.value = ANALYSIS_SIDE_LIST.map((group) => ({
  value: group.groupId,
  label: group.title,
  children: group.children.map((child) => ({
    value: child.algorithmId,
    label: child.name,
  })),
}));
const analysisTypeProps = {
  expandTrigger: "hover",
  hoverThreshold: 2000,
};

const searchAnalysisList = () => {
  if (analysisType.value) {
    getAnalysisList(analysisType.value[1], searchAnalysis.value);
  } else {
    getAnalysisList(analysisType.value, searchAnalysis.value);
  }
};

// 多选的数据项，用于批量操作
const selectedItemList = ref([]); //存储选中item
// 列表勾选变化时，将被选中的item存入selectedItemList
const handleSelectionChange = (val) => {
  selectedItemList.value = val;
};

const handleClickRename = (row) => {
  row.ifEdit = true;
};

// 保存重命名
const handleClickSaveListItemName = Debounce(async (text, row) => {
  row.rename = text;
  if (text.length < 1) {
    proxy.message.warning("名称不可为空");
    row.ifShowLoading = false;
  } else {
    let res = await updateAnalysisName(row);
    if (res.code == 0) {
      proxy.message.success(res.message);
      await getAnalysisList();
      row.ifShowLoading = false;
      row.ifEdit = false;
      emits("refreshTabs", { analysisId: row.id, rename: row.rename });
    } else if (res.code == ERROR_CODE_NAME_DUPLICATE) {
      proxy.message.warning(res.message);
      row.ifShowLoading = false;
    }
  }
});
// 点击列表项名称
const handleClickName = (row) => {
  let analysisInfo = {
    algorithmId: row.algorithmId || null,
    analysisId: row.id || null,
    name: row.name || null,
    state: row.state || null,
  };
  emits("analysisInfo", analysisInfo);
};

// 点击更多操作
const handleSingleAnalysis = (row, command) => {
  let id;
  switch (command) {
    case "rename":
      handleClickRename(row);
      break;
    case "delete":
      confirmDeleteBox("是否确认删除")
        .then(() => {
          id = [row.id];
          deleteAnalysisList(id);
        })
        .catch();
      break;
  }
};

const analysisDataList = ref([]);
const tableLoading = ref(false);
// 获取分析列表
const getAnalysisList = async (algorithmId, keywords) => {
  tableLoading.value = true;
  let params = {
    libId: libraryId,
    algorithmId: algorithmId,
    keywords: keywords,
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  let res = await httpPost(`/analysis/v1/getPageList`, params);
  if (res.code == 0) {
    analysisDataList.value = [];
    res.data.data.forEach((element) => {
      analysisDataList.value.push(element);
    });
    pageNum.value = res.data.pageNum;
    total.value = res.data.total;
    pageSize.value = res.data.pageSize;
    tableLoading.value = false;
  }

  return res;
};

const updateAnalysisName = async (row) => {
  let params = {
    id: row.id,
    name: row.rename,
  };
  let res = await httpPost("/analysis/v1/updateName", params);
  return res;
};

// 删除操作
const handleBatchDelete = () => {
  if (selectedItemList.value.length == 0) {
    proxy.message.warning("请先勾选分析");
  } else {
    confirmDeleteBox(
      `确认删除选中的` + selectedItemList.value.length + `条分析吗`
    ).then(() => {
      let idList = [];
      selectedItemList.value.forEach((element) => {
        idList.push(element.id);
      });
      deleteAnalysisList(idList);
    });
  }
};

const deleteAnalysisList = async (idList) => {
  let params = {
    idList: idList,
  };
  let res = await httpPost("/analysis/v1/deleteList", params);
  if (res.code == 0) {
    proxy.message.success(res.message);
  } else {
    proxy.message.success("删除失败");
  }
  getAnalysisList();
};

const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getAnalysisList,
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";
.search-option-bar {
  display: flex;
  justify-content: space-between;
  .search-bar {
    width: 360px;
  }
  .option-bar {
    span:first-of-type {
      display: inline-block;
      width: 160px;
    }
    span {
      margin-left: 14px;
    }
  }
}
.analysis-list {
  padding: 24px 20px;
  .el-tag {
    width: 56px;
    height: 28px;
    font-size: 14px;
    font-weight: 500;
    border: 0px;
  }
  .el-tag--success {
    background-color: rgba(8, 184, 9, 0.2);
    color: rgba(8, 184, 9, 1);
  }
  .el-tag--warning {
    background-color: rgba(244, 143, 66, 0.2);
    color: rgba(244, 143, 66, 1);
  }
  .el-tag--danger {
    background-color: rgba(251, 92, 92, 0.2);
    color: rgba(251, 92, 92, 1);
  }
  .el-tag--info {
    background-color: rgba(173, 172, 172, 0.2);
    color: rgba(173, 172, 172, 1);
  }
  .more-operation {
    color: $theme-color;
  }
}
</style>
