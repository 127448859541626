<!-- 岭回归分析 分析条件-->
<template>
  <div>
    <AnalysisConditionForm
      ref="analysisConditionFormRef"
      :analysis-id="tabInfo.analysisId"
      :analysis-type="tabInfo.algorithmId"
      :title="tabInfo.name"
      :library-id="libraryId"
      :analysis-config="analysisConfig"
      :all-variable-list="allVariableList"
      :target-props="targetProps"
      @click-start="onClickStart"
      :loading="analysisConditionLoading"
      :rules="rules"
      @reset="resetAnalysisConfig"
      @refreshVarList="refreshVariableDataList"
      @addVariableToTargetProp="addVariableToAnalysisConfigByTargetProp"
      :transfer-options="transferOptions"
    >
      <AnalysisConditionFormItem prop="variables1">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variables1"
            row="1"
            @onEnd="onEnd"
            title="因变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加因变量Y',
                MEASURE_STANDARD.RATION,
                '变量(变量数=1)'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <AnalysisConditionFormItem prop="variables2">
        <template v-slot="{ prop, draggableValidator, onEnd }">
          <VariableTargetTransfer
            :name="prop"
            v-model="analysisConfig.variables2"
            row="auto"
            @onEnd="onEnd"
            title="自变量"
            :draggableValidator="draggableValidator"
            :tip="
              getAnalysisStandardHtmlText(
                '拖拽添加自变量X',
                MEASURE_STANDARD.RATION,
                '或者'
              ) +
              getAnalysisStandardHtmlText(
                '',
                MEASURE_STANDARD.CATEGORIZATION,
                '（变量数≧1）'
              )
            "
          ></VariableTargetTransfer>
        </template>
      </AnalysisConditionFormItem>
      <div class="analysis-subtype">
        <div class="analysis-subtype-item">
          <div class="analysis-subtype-item-item">
            <el-switch v-model="ifLmuda" />
            自动选择λ值
            <BaseTooltip>
              <template #content>
                <p style="max-width: 250px">
                  λ值用于调节惩罚函数的大小，随着λ增大，共线性的影响将越来越小，λ值越小则偏差越小，λ值为0时则为普通线性OLS回归，对于λ值，其越小越好，通常建议小于1，浮点数。
                </p>
              </template>
              <i class="iconfont icon-wenhao-tishi info-message"></i>
            </BaseTooltip>
          </div>
          <div class="analysis-subtype-item-item">
            λ值：
            <!-- TODO:默认值及值的范围需确认 -->
            <el-input-number
              v-model="analysisSubType1"
              :min="0"
              :max="1"
              :step="0.1"
              controls-position="right"
            />
          </div>
        </div>
        <div class="analysis-subtype-item">
          <div class="analysis-subtype-item-item">
            <el-switch v-model="ifComponent" />
            保存残差和预测值
          </div>
        </div>
      </div>
    </AnalysisConditionForm>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  onMounted,
  getCurrentInstance,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import { useAnalysisCondition } from "@/utils/useAnalysisCondition";
import { Debounce } from "@/utils/utils";

import {
  MEASURE_STANDARD,
  getAnalysisStandardHtmlText,
} from "@/constant/variable_standard.js";
import BaseTooltip from "@/components/base/BaseTooltip.vue";

import AnalysisConditionForm from "./AnalysisConditionForm.vue";
import AnalysisConditionFormItem from "./AnalysisConditionFormItem.vue";
import VariableTargetTransfer from "@/components/variableTransfer/VariableTargetTransfer.vue";

const { proxy } = getCurrentInstance();
const emits = defineEmits(["showLoading"]);
const props = defineProps({
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const ifComponent = ref(true);
const ifLmuda = ref(false);
// TODO:默认值及值的范围需确认
const analysisSubType1 = ref(0.01);
const analysisSubType2 = ref(0.1);

const libraryId = inject("libraryId");
const targetProps = ref(["variables1", "variables2"]);
const transferOptions = ref([
  { prop: "variables1", label: "因变量" },
  { prop: "variables2", label: "自变量" },
]);
const rules = ref({
  variables1: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【因变量】变量个数不能少于1");
      },
    },
    {
      max: 1,
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning("【因变量】变量个数不能大于1");
      },
    },
    {
      variableStandard: [MEASURE_STANDARD.RATION],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message: getAnalysisStandardHtmlText(
            "请添加",
            MEASURE_STANDARD.RATION,
            "变量"
          ),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
  variables2: [
    {
      min: 1,
      validateErrorCallback: () => {
        proxy.message.warning("【自变量】变量个数不能少于1");
      },
    },
    {
      variableStandard: [
        MEASURE_STANDARD.CATEGORIZATION,
        MEASURE_STANDARD.RATION,
      ],
      trigger: "move",
      moveErrorCallback: () => {
        proxy.message.warning({
          message:
            getAnalysisStandardHtmlText(
              "请添加",
              MEASURE_STANDARD.CATEGORIZATION,
              "或"
            ) +
            getAnalysisStandardHtmlText("", MEASURE_STANDARD.RATION, "变量"),
          dangerouslyUseHTMLString: true,
        });
      },
    },
  ],
});

const {
  allVariableList,
  analysisConfig,
  analysisConditionLoading,
  generateAnalysisConfigOfParams,
  resetAnalysisConfig,
  refreshVariableDataList,
  addVariableToAnalysisConfigByTargetProp,
} = useAnalysisCondition({
  libraryId,
  analysisId: props.tabInfo.analysisId,
  targetProps: targetProps.value,
  defaultAnalysisConfig: {
    variables1: [],
    variables2: [],
  },
});

const analysisConditionFormRef = ref(null);
const showLoading = () => {
  emits("showLoading");
};

// TODO:接口参数名有待确认

const onClickStart = async () => {
  analysisConditionFormRef.value.validate().then(async (res) => {
    if (res.valid) {
      let params = {
        libId: libraryId,
        analysisId: props.tabInfo.analysisId,
        analysisConfig: JSON.stringify({
          ...generateAnalysisConfigOfParams(),
        }),
      };
      showLoading();

      let res = await httpPost("/analysis/v1/saveAndRun", params);
      if (res.code == 0) {
      }
    } else {
      res.invalidList.forEach((fieldResult) => {
        fieldResult?.rule?.validateErrorCallback();
      });
    }
  });
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/analysis-condition-tool.scss";
</style>
