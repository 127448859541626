<template>
  <!-- BaseTooltip -->
  <!-- <div @mouseenter="handleMouseenter" @mouseleave="mouseleave">
    <el-tooltip
      :placement="placement"
      :disabled="disabled"
      :content="content"
      :effect="effect"
      :trigger="trigger"
    >
      <slot></slot>
    </el-tooltip>
  </div> -->
  <el-tooltip
    :placement="placement"
    :content="String(content)"
    :effect="effect"
    :trigger="trigger"
    :rawContent="rawContent"
    :popper-class="popperClass"
  >
    <template #content>
      <slot name="content"></slot>
    </template>
    <slot></slot>
  </el-tooltip>
</template>

<script setup>
import { ref, defineProps } from "vue";
// import BaseTooltip from "@/components/base/BaseTooltip.vue";

const props = defineProps({
  content: {
    type: [String, Number],
    default: null,
  },
  effect: {
    type: String,
    default: "customized",
  },
  trigger: {
    type: String,
    default: "hover",
  },
  placement: {
    type: String,
    default: "top",
  },
  rawContent: {
    type: Boolean,
    default: false,
  },
  popperClass: {
    type: String,
    default: null,
  },
});

const disabled = ref(true);

const handleMouseenter = (e) => {
  if (!props.content) {
    return;
  }
  const cellChild = e.target.children[0];
  // range 表示文档的一个区域
  const range = document.createRange();
  range.setStart(cellChild, 0);
  range.setEnd(cellChild, cellChild.childNodes.length);

  const flag = getStyle(cellChild, "-webkit-line-clamp");
  if (flag == "none") {
    // rangeWidth 表示元素内容的宽度
    const rangeWidth = range.getBoundingClientRect().width;
    let padding =
      (parseInt(getStyle(cellChild, "paddingLeft")) || 0) +
      (parseInt(getStyle(cellChild, "paddingRight")) || 0);

    // cellChild.offsetWidth 表示选定区域的宽度
    if (rangeWidth > cellChild.offsetWidth - padding) {
      // 显示tooltip
      disabled.value = false;
    }
  } else {
    // rangeHeight 表示元素内容的高度
    const rangeHeight = range.getBoundingClientRect().height;
    let padding =
      (parseInt(getStyle(cellChild, "paddingTop")) || 0) +
      (parseInt(getStyle(cellChild, "paddingBottom")) || 0);

    // cellChild.offsetHeight 表示选定区域的高度
    if (rangeHeight > cellChild.offsetHeight - padding) {
      // 显示tooltip
      disabled.value = false;
    }
  }
};

const mouseleave = () => {
  disabled.value = true;
};

// 获取dom的样式
const getStyle = (dom, attr) => {
  return getComputedStyle(dom, null)[attr];
};
</script>

<style lang="scss">
.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.6);
}

.el-popper.is-customized .el-popper__arrow::before {
  background: #ffffff;
  // box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.6);
}
</style>
