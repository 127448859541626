import { h } from "vue";

const MEASURE_STANDARD = {
  RATION: "1", //定量
  CATEGORIZATION: "2", //定类
  OTHER: "3", //其他
};
const MEASURE_STANDARD_MAP = {
  [MEASURE_STANDARD.RATION]: {
    type: "success",
    label: "定量",
    standard: MEASURE_STANDARD.RATION,
    color: "#DAF5DA",
    fontColor: "#08B809",
  },
  [MEASURE_STANDARD.CATEGORIZATION]: {
    type: "warning",
    label: "定类",
    standard: MEASURE_STANDARD.CATEGORIZATION,
    // color: "#F49146",
    color: "#FEEEE3",
    fontColor: "#F48F42",
  },
  [MEASURE_STANDARD.OTHER]: {
    type: "",
    label: "其他",
    standard: MEASURE_STANDARD.OTHER,
    // color: "#9F8EE7",
    color: "#EFECFB",
    fontColor: "#9480E4",
  },
};
const MEASURE_STANDARD_LIST = Object.keys(MEASURE_STANDARD_MAP).map((key) => ({
  label: MEASURE_STANDARD_MAP[key].label,
  value: key,
}));

const getAnalysisStandardVnodeText = (textBefore, type, textAfter) => {
  let analysisMessage = h("p", null, [
    h("span", null, textBefore),
    h(
      "span",
      {
        style: `color: ${MEASURE_STANDARD_MAP[type].fontColor}`,
      },
      `【${MEASURE_STANDARD_MAP[type].label}】`
    ),
    h("span", null, textAfter),
  ]);
  return analysisMessage;
};

const getAnalysisStandardHtmlText = (textBefore, type, textAfter) => {
  let analysisMessage = `${textBefore} <span style="color: ${MEASURE_STANDARD_MAP[type].fontColor};">【${MEASURE_STANDARD_MAP[type].label}】</span> ${textAfter}`;
  return analysisMessage;
};

const getColValueString = (colValue) => {
  let resultString = "";
  for (let key in colValue) {
    resultString += `{${key},${colValue[key] || ""}};`;
  }
  return resultString;
};

export {
  MEASURE_STANDARD_LIST,
  MEASURE_STANDARD,
  MEASURE_STANDARD_MAP,
  getAnalysisStandardVnodeText,
  getAnalysisStandardHtmlText,
  getColValueString,
};
