// 简易封装一些消息框，方便使用
import { ElNotification, ElMessage, ElMessageBox } from "element-plus";

export const confirmDeleteBox = (
  content = "是否确认删除",
  title = "提示",

  type = "warning",
  confirmButtonText = "确认",
  cancelButtonText = "取消"
) => {
  return ElMessageBox.confirm(content, title, {
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    type: type,
  });
};
