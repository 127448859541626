const VARIABLE_TYPE = {
  NUMBER: "N",
  STRING: "S",
  DATE: "D",
};

const VARIABLE_MAP = {
  [VARIABLE_TYPE.NUMBER]: {
    label: "数值",
    labelEn: "number",
    value: "N",
    maxLen: 16,
    decimal: 2,
    icon: "icon-bianliang-shuzhi",
  },
  [VARIABLE_TYPE.STRING]: {
    label: "字符串",
    labelEn: "string",
    value: "S",
    maxLen: 225,
    decimal: 0,
    icon: "icon-bianliang-wenben",
  },
  [VARIABLE_TYPE.DATE]: {
    label: "日期",
    labelEn: "date",
    value: "D",
    maxLen: 0,
    decimal: 0,
    icon: "icon-bianliang-riqi",
  },
};

const VARIABLE_TYPE_LIST = Object.keys(VARIABLE_MAP).map((key) => ({
  label: VARIABLE_MAP[key].label,
  value: key,
}));

export { VARIABLE_TYPE, VARIABLE_TYPE_LIST, VARIABLE_MAP };
